import React from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import Header from "../shared/headers/Header";
import Sidebar from "../shared/sidebars/Sidebar";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { SIDEBAR_STYLES } from "@jumbo/utils/constants";
import { headerTheme as theme4 } from "../../themes/header/theme4";
import { headerTheme as defaultTheme } from "../../themes/header/default";
import useApp from "../../hooks/useApp";
import layoutConfig from "./layoutConfig";
import { mainTheme as mainThemeDark } from "app/themes/main/dark";
import { headerTheme as headerThemeDark } from "app/themes/header/dark";
import { footerTheme as footerThemeDark } from "app/themes/footer/dark";
import { sidebarTheme as sidebarThemeDark } from "app/themes/sidebar/dark";

import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import useJumboFooterTheme from "@jumbo/hooks/useJumboFooterTheme";

import menus from "app/layouts/shared/sidebars/Sidebar/menus";

const VerticalDefault = ({ children }) => {
  const { setJumboLayoutOptions } = useJumboLayout();
  const { headerTheme, setHeaderTheme } = useJumboHeaderTheme();
  const { theme, setTheme } = useJumboTheme();
  const appBarBgColor =
    headerTheme.components?.MuiAppBar?.styleOverrides?.root?.background;
  const { sidebarOptions } = useJumboLayoutSidebar();
  const appData = useApp();
  const { setSidebarTheme } = useJumboSidebarTheme();
  const { setFooterTheme } = useJumboFooterTheme();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => {
    setJumboLayoutOptions(layoutConfig);

    await setTheme({ mode: "dark", ...mainThemeDark });
    setHeaderTheme({ ...theme, ...headerThemeDark });
    setSidebarTheme({ ...theme, ...sidebarThemeDark });
    setFooterTheme({ ...theme, ...footerThemeDark });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      appBarBgColor === "#F5F7FA" &&
      sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
    ) {
      setHeaderTheme({ ...theme, ...theme4 });
      appData.setAppState({
        prevHeaderBgColor: theme?.mode === "dark" ? "#17181A" : "#F5F7FA",
      });
    } else if (
      appData.prevHeaderBgColor &&
      appData.prevHeaderBgColor === "#F5F7FA"
    ) {
      setHeaderTheme({ ...theme, ...defaultTheme });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarOptions.style]);

  return (
    <JumboLayout
      header={<Header />}
      sidebar={<Sidebar menuData={menus} />}
      footer={null}
      headerSx={{
        height: 80,
      }}
    >
      {children}
      {/* <JumboCustomizer />
      <JumboCustomizerButton /> */}
    </JumboLayout>
  );
};

export default VerticalDefault;
