import { Box, Typography, Card, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import LogoutIcon from "@mui/icons-material/Logout";
import { getDate } from "@jumbo/utils";
import BasicLayout from "app/layouts/content-layouts/BasicLayout";
import PATH from "app/routes/path";
import { deleteCookie } from "app/utils/appHelpers";

const AccountMgt = () => {
  const { setAuthToken, authUser } = useJumboAuth();

  const navigate = useNavigate();

  const onLogout = () => {
    setAuthToken(null);
    navigate("/user/login");
    deleteCookie("token");
  };

  return (
    <BasicLayout header={<h1>Account Management</h1>}>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          margin: "30px auto",
        }}
      >
        <Card
          sx={{
            margin: "0 auto",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "20px",
            justifyContent: "space-between",
            p: 4,
            maxWidth: "660px",
            "& span": {
              fontSize: "16px",
            },
            "& a": {
              textAlign: "right",
              color: "#fff",
              fontSize: "16px",
            },
          }}
        >
          {/* <Typography component="span" textAlign={`left`}>
            Your account type
          </Typography>
          <Typography component="span" textAlign={`right`}>
            You are a CPC Lite User!
          </Typography> */}
          <Typography component="span" textAlign={`left`}>
            You joined on
          </Typography>
          <Typography component="span" textAlign={`right`}>
            {authUser && getDate(authUser.email_verified_at)}
          </Typography>
          <Typography component="span" textAlign={`left`}>
            Your current email
          </Typography>
          <Typography component="span" textAlign={`right`}>
            {authUser && authUser.email}
          </Typography>
          {/* <Typography component="span" textAlign={`left`}>
            To upgrade your plan
          </Typography>
          <Link to={PATH.subcription}>Click here</Link> */}

          <Box></Box>
          <Typography
            component={Link}
            to={PATH.changePassword}
            textAlign={`left`}
            sx={{ textDecoration: "underline" }}
          >
            Change password
          </Typography>
        </Card>
        <Box
          width="660px"
          textAlign={`left`}
          mt={2}
          sx={{
            margin: "20px auto",
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            onClick={onLogout}
            sx={{ cursor: "pointer" }}
          >
            <LogoutIcon />
            &nbsp; Logout
          </Stack>
        </Box>
      </Box>
    </BasicLayout>
  );
};

export default AccountMgt;
