import React from "react";
import { Typography, Box } from "@mui/material";
import PATH from "app/routes/path";
import { Link } from "react-router-dom";

export function ColumnPost({ post, type }) {
  return (
    <Box className="column_post">
      <Box p={"1.5 0"} sx={{ width: "100%" }}>
        <Typography
          variant={"h6"}
          mb={0.5}
          fontSize={18}
          color="#fff"
          className="post_title"
        >
          {post.title && post.title.rendered}
        </Typography>
        <Box
          className="clipped-content"
          color="inherit"
          dangerouslySetInnerHTML={{
            __html: post.excerpt && post.excerpt.rendered,
          }}
        ></Box>

        <Typography
          className="readmore_column"
          component={Link}
          to={PATH.reports + "/" + type + "/details/" + post.id}
        >
          {`Read more >>`}
        </Typography>
      </Box>
    </Box>
  );
}
