import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import ListItemButton from "@mui/material/ListItemButton";
import { ListItemIcon } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import { SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import { useTranslation } from "react-i18next";
import PATH from "app/routes/path";
import { INSIGHT_HOST } from "app/utils/constants/paths";

const menuBefore = {
  left: 0,
  top: 0,
  content: `''`,
  position: "absolute",
  display: "inline-block",
  width: "4px",
  height: "100%",
  backgroundColor: "transparent",
};

const JumboNavItem = ({ item, isNested, translate }) => {
  const location = useLocation();
  const { sidebarOptions } = useJumboLayoutSidebar();
  const { t } = useTranslation();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  const label = React.useMemo(() => {
    return translate ? t(item.label) : item.label;
  }, [item, translate, t]);

  const reloadHome = (url) => {
    if (url === PATH.home) {
      window.location.href = INSIGHT_HOST;
    }
  };
  if (!item) return null;

  return (
    <ListItemButton
      component={"li"}
      className="menu_item_border"
      sx={{
        p: 0,
        overflow: "hidden",

        margin: isMiniAndClosed ? "0 auto" : "0",
        ...(isMiniAndClosed
          ? { width: 40, height: 40, justifyContent: "center" }
          : {}),
        ...(!isMiniAndClosed ? { "&::before": menuBefore } : {}),
        "&:hover": {
          color: "#A9F000",
          backgroundColor: "#202123",
          "& svg": {
            fill: "#A9F000",
          },
          ...(!isMiniAndClosed
            ? {
                "&::before": {
                  ...menuBefore,
                  backgroundColor: (theme) => theme.palette.nav.tick.hover,
                },
              }
            : {}),
        },
        ...(location.pathname === item.uri
          ? {
              color: "#A9F000",
              backgroundColor: "#202123",
              "& svg *": {
                fill: "#A9F000 !important",
              },
              ...(!isMiniAndClosed
                ? {
                    "&::before": {
                      ...menuBefore,
                      backgroundColor: (theme) => theme.palette.nav.tick.active,
                    },
                  }
                : {}),
            }
          : {}),
      }}
    >
      <Link
        underline={"none"}
        component={item.uri.includes("/category/") ? "a" : RouterLink}
        to={item.uri}
        href={item.uri.includes("/category/") ? item.uri : "#"}
        onClick={() => reloadHome(item.uri)}
        {...(item.target ? { target: item.target } : {})}
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
          position: "relative",
          color: "inherit",
          p: (theme) => (!isMiniAndClosed ? theme.spacing(1, 3.75) : 0),
          ...(isMiniAndClosed ? { justifyContent: "center" } : {}),
        }}
      >
        <ListItemIcon sx={{ minWidth: isMiniAndClosed ? 20 : 32 }}>
          {item.icon}
        </ListItemIcon>
        {!isMiniAndClosed && (
          <ListItemText
            primary={label}
            sx={{
              m: 0,
              "& .MuiTypography-root": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          />
        )}
      </Link>
    </ListItemButton>
  );
};

export default JumboNavItem;
