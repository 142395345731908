import { Box, Pagination, Card, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import BasicLayout from "app/layouts/content-layouts/BasicLayout";
import NoData from "app/components/NoData";
import Breadcrumb from "app/components/Breadcrumb";
import { Post } from "./components/Post";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import useFetch from "app/hooks/useFetch";
import useQuery from "app/hooks/useQuery";
import _ from "lodash";
import WithLoading from "app/components/WithLoading";
import { ColumnPost } from "./components/ColumnPost";
import { ReactComponent as News } from "app/assets/images/Report.svg";
import PATH from "app/routes/path";

const Reports = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const query = useQuery();
  const location = useLocation();

  const { isLoading, data } = useFetch(
    {
      path: "posts",
      options: {
        params: {
          categories: id,
          _embed: "",
          page: query.get("page"),
          per_page: 7,
        },
      },
      dependent: [id, query.get("page")],
    },
    false
  );

  const { isLoading: isCategoryLoading, data: categories } = useFetch(
    {
      path: "categories",
      options: {
        params: { _embed: "", per_page: 100 },
      },
      dependent: [1],
    },
    false
  );

  const handleChangePage = useCallback(
    (_event, newPage) => {
      navigate(location.pathname + "?page=" + newPage);
    },
    [location.pathname, navigate]
  );

  const getCategory = useCallback(
    (category_id) => {
      if (categories) {
        const item = categories.find((it) => it.id === category_id);
        return {
          label: item && item.name && _.unescape(item.name),
          uri: PATH.reports + "/" + category_id,
          parent: item && item.parent,
        };
      } else return null;
    },
    [categories]
  );

  const getBreadCrumb = useMemo(() => {
    const breadcrumb = [];
    const item = getCategory(+id);
    if (item) {
      breadcrumb.unshift(item);
      if (item.parent) {
        breadcrumb.unshift(getCategory(item.parent));
      }
    }
    return breadcrumb;
  }, [getCategory, id]);

  return (
    <BasicLayout>
      <Breadcrumb data={getBreadCrumb} />
      <WithLoading isLoading={isLoading || isCategoryLoading}>
        <Box sx={{ width: "100%" }} className="post_page">
          {data && data.length ? (
            <Box className="grid_frame grid_frame_new">
              <Box class="flex_column">
                {data &&
                  data.map((post, index) => (
                    <Post post={post} type={id} isColumn={false} />
                  ))}

                {data && data.total > 1 && (
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                    mb={4}
                  >
                    <Pagination
                      count={data && data.total}
                      page={query.get("page") ?? 1}
                      showFirstButton
                      showLastButton
                      onChange={handleChangePage}
                    />
                  </Box>
                )}
              </Box>
              <Card className="side_column">
                <Box className="box_flex fill_gray">
                  <News />
                  <Typography componet="h3" className="header_title">
                    Most read news
                  </Typography>
                </Box>
                {data && data.length ? (
                  data.map((post, index) => (
                    <ColumnPost
                      post={post}
                      type={post && post.categories && post.categories[0]}
                    />
                  ))
                ) : (
                  <NoData />
                )}
              </Card>
            </Box>
          ) : (
            <NoData />
          )}
        </Box>
      </WithLoading>
    </BasicLayout>
  );
};

export default Reports;
