import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
} from "recharts";
import moment from "moment";
import { Box, Paper } from "@mui/material";

const TokenBalance = () => {
  const data = Array.from(Array(12).keys())
    .map((item) => {
      const cex =
        Number((Math.round(Math.random() * 100) / 100).toFixed(2)) * 100;
      const staking =
        Number((Math.round(Math.random() * 100) / 100).toFixed(2)) * 100;
      const funds =
        Number((Math.round(Math.random() * 100) / 100).toFixed(2)) * 100;
      const dex =
        Number((Math.round(Math.random() * 100) / 100).toFixed(2)) * 100;
      const smartMoney =
        Number((Math.round(Math.random() * 100) / 100).toFixed(2)) * 100;

      const bridges =
        Number((Math.round(Math.random() * 100) / 100).toFixed(2)) * 100;
      return {
        name: moment().subtract(item, "months").format("MM.YY"),
        cex: cex < 40 ? cex + 40 : cex - 10,
        staking: staking < 40 ? staking + 10 : staking - 20,
        funds: funds < 40 ? funds + 10 : funds - 30,
        dex: dex < 40 ? dex + 10 : dex - 20,
        smartMoney: smartMoney < 40 ? smartMoney + 10 : smartMoney - 30,
        bridges: bridges < 40 ? bridges + 10 : bridges - 20,
      };
    })
    .reverse();

  return (
    <Box position="relative" mt={3} mb={3}>
      <Paper pr={3}>
        <Box className="main_title" p={3}>
          Token Balance
        </Box>
        <Box className="vertical_title">% of Total Supply</Box>
        <ResponsiveContainer width={"100%"} height={400}>
          <LineChart
            width={400}
            height={400}
            data={data}
            margin={{ top: 5, right: 20, left: 20, bottom: 40 }}
          >
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              stroke="#fff"
            />
            <YAxis
              type="number"
              domain={[0, 100]}
              tickLine={false}
              axisLine={false}
              stroke="#fff"
            />
            <Tooltip />
            <CartesianGrid stroke="#404854" vertical={false} />
            <Line
              type="monotone"
              dataKey="cex"
              name="CEX"
              stroke="#FF7F7F"
              yAxisId={0}
              strokeWidth={3}
            />
            <Line
              type="monotone"
              dataKey="staking"
              name="Staking"
              stroke="#A9F000"
              yAxisId={0}
              strokeWidth={3}
            />
            <Line
              type="monotone"
              dataKey="funds"
              name="Funds"
              stroke="#ADD8E6"
              yAxisId={0}
              strokeWidth={3}
            />
            <Line
              type="monotone"
              dataKey="dex"
              stroke="#FFB6C1"
              name="DEX"
              yAxisId={0}
              strokeWidth={3}
            />
            <Line
              type="monotone"
              dataKey="smartMoney"
              stroke="#387908"
              name="Smart Money"
              yAxisId={0}
              strokeWidth={3}
            />
            <Line
              type="monotone"
              dataKey="bridges"
              name="Bridges"
              stroke="#ff7300"
              yAxisId={0}
              strokeWidth={3}
            />
            <Legend
              targetX={0}
              surfaceScale={0}
              align="left"
              iconType="circle"
              iconSize={10}
              formatter={(value, entry, index) => {
                return (
                  <Box
                    component="span"
                    className="text-color-class"
                    sx={{ padding: "8px" }}
                  >
                    {value}
                  </Box>
                );
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Paper>
    </Box>
  );
};

export default TokenBalance;
