import axios from "./config";

const requestService = async ({
  method = "get",
  path,
  data = null,
  params = null,
  headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
}) => {
  const url = path;
  try {
    const { data: responses } = await axios({
      method,
      url,
      data,
      headers,
      params,
    });
    return { data: responses?.data, error: null };
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.href = "/user/login";
    }
    return { data: null, error: error };
  }
};

export default requestService;
