import { Box, Typography } from "@mui/material";
import React from "react";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import RoomIcon from "@mui/icons-material/Room";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CourseImg from "app/assets/images/course.png";

export const Course = ({ name, time, place, registration_time, id }) => {
  return (
    <>
      <Box
        className="course_img"
        sx={{
          background: `url(${CourseImg}) center no-repeat`,
          backgroundSize: "cover",
        }}
      ></Box>
      <Box textAlign={"left"} padding="12px 12px 12px 20px">
        <Typography component="h4" className="course_header">
          {name}
        </Typography>
        <Typography className="course_content">
          <PendingActionsIcon />
          Thời gian:
          {time}
        </Typography>
        <Typography className="course_content">
          <RoomIcon />
          Địa điểm: {place}
        </Typography>
        <Typography className="course_content last_item">
          <HourglassTopIcon />
          Hạn chót đăng kí: {registration_time}
        </Typography>
        <Box className="course_button register_course_button">Đăng ký</Box>
      </Box>
    </>
  );
};
