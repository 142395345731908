import { Box, Typography } from "@mui/material";

const Transactions = () => {
  return (
    <Box mt={4} className="grid_box">
      <Box className="grid_item">
        <Typography className="grid_item_title">Token</Typography>
        <Typography className="grid_item_title subtitle purple">
          Fetch AI
        </Typography>
      </Box>
      <Box className="grid_item">
        <Typography className="grid_item_title">Token price</Typography>
        <Typography className="grid_item_title subtitle">$49,494.39</Typography>
      </Box>
      <Box className="grid_item">
        <Typography className="grid_item_title">
          Fully Diluted valuation
        </Typography>
        <Typography className="grid_item_title subtitle">$39M</Typography>
      </Box>
      <Box className="grid_item">
        <Typography className="grid_item_title">Deployed</Typography>
        <Typography className="grid_item_title subtitle">340d ago</Typography>
      </Box>
    </Box>
  );
};

export default Transactions;
