import { Box } from "@mui/material";
// import React from "react";

// import { ReactComponent as Avatar } from "app/assets/images/profile_avatar.svg";
// import { ReactComponent as CopyIcon } from "app/assets/images/copy_icon.svg";
// import { ReactComponent as QrIcon } from "app/assets/images/qr_icon.svg";
// import { ReactComponent as EthIcon } from "app/assets/images/eth_icon.svg";
import { ReactComponent as WalletIcon } from "app/assets/images/wallet_icon.svg";
import useFetch from "app/hooks/useFetch";
import WithLoading from "app/components/WithLoading";

import BasicLayout from "app/layouts/content-layouts/BasicLayout";
import { Header } from "./components/styled";
import { ProfileTable } from "./components/Table";
import "./profile.css";
import useQuery from "app/hooks/useQuery";
import NoData from "app/components/NoData";
import Web3 from "web3";

const Profile = () => {
  const query = useQuery();
  const { isLoading: isLoadingContractDetail, data: profileData } = useFetch({
    path: `address/${query.get("address")}/portfolio`,
    options: {
      params: {},
    },
    dependent: query.get("address"),
  });

  return (
    <BasicLayout header={<h1>Profile</h1>}>
      <WithLoading isLoading={isLoadingContractDetail}>
        <Box sx={{ width: "100%" }} className="profile_page">
          {Web3.utils.isAddress(query.get("address")) ? (
            <Box>
              {/* <Box
                mt={4}
                mb={4}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "120px 1fr",
                  gap: "20px",
                  "& svg": {
                    width: "120px",
                  },
                }}
              >
                <Avatar />
                <Box>
                  <Typography
                    variant="h5"
                    fontSize={"24px"}
                    lineHeight={"32px"}
                  >
                    No ID
                  </Typography>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Typography variant="span">
                      0x456cf3c2c0f666823740a1a9d0de542f7e7b830f
                    </Typography>
                    <Icon onClick={() => {}}>
                      <CopyIcon />
                    </Icon>
                    <Icon onClick={() => {}}>
                      <QrIcon />
                    </Icon>
                  </Box>
                  <Typography>This user has not added bio yet</Typography>
                </Box>
              </Box>
              <Header icon={<WalletIcon />} title="Portfolio" />
              <Box className="section" mt={2} mb={3}>
                <Box className="common_flex common_gap_20">
                  <EthIcon />
                  <Box>
                    <Typography>Ethereum</Typography>
                    <Typography sx={{ fontSize: "18px", lineHeight: "22px" }}>
                      $1,766,324
                    </Typography>
                  </Box>
                </Box>
              </Box> */}
              <Box>
                <Header icon={<WalletIcon />} title="Wallet" />
                <Box className="section" mt={2} mb={3}>
                  <ProfileTable data={profileData ?? []} />
                </Box>
              </Box>
            </Box>
          ) : (
            <NoData />
          )}
        </Box>
      </WithLoading>
    </BasicLayout>
  );
};

export default Profile;
