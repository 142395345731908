import React, { Suspense } from "react";
import { IconButton, useMediaQuery, Box } from "@mui/material";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import { SIDEBAR_STYLES } from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
// import { ReactComponent as NTF } from "app/assets/images/NTF.svg";
// import { ReactComponent as PricePlan } from "app/assets/images/PricePlan.svg";
// import { Link } from "react-router-dom";
// import PATH from "app/routes/path";

const Sidebar = ({ menuData }) => {
  return (
    <React.Fragment>
      <SidebarHeader />
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                alignContent: "center",
                px: 3,
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          <JumboVerticalNavbar translate items={menuData} />
          {/* <Box className="mint_button" component={Link} to={PATH.connectWallet}>
            <NTF />
            Nfts Mint
          </Box>
          <Box
            className="mint_button grey_btn"
            component={Link}
            to={PATH.subcription}
          >
            <PricePlan />
            Price Plan
          </Box> */}
        </Suspense>
      </JumboScrollbar>
    </React.Fragment>
  );
};

const SidebarHeader = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { sidebarTheme } = useJumboSidebarTheme();
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <Box className="header_logo">
          <Logo mini={isMobile} mode={sidebarTheme.type} />
          {isMobile && (
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                onClick={() => setSidebarOptions({ open: false })}
              >
                <MenuOpenIcon />
              </IconButton>
            </Zoom>
          )}
        </Box>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
