import React from "react";
import { Typography, Card, CardContent, Box } from "@mui/material";
import Div from "@jumbo/shared/Div";
import Logo from "app/shared/Logo";
import { ReactComponent as MetaMask } from "app/assets/images/MetaMask.svg";
import { Link } from "react-router-dom";
import PATH from "app/routes/path";

const ConnectWallet = () => {
  return (
    <Box className="fix_height">
      <Div
        className="card_wrapper"
        sx={{
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: (theme) => theme.spacing(4),
          "& a": {
            color: "#A9F000",
          },
        }}
      >
        <Card
          sx={{ maxWidth: "100%", width: 360, mb: 4 }}
          className="card_form wallet_box"
        >
          <CardContent sx={{ pt: 5 }}>
            <Typography component="h3" className="header_title mb_2">
              Select Your Wallet
            </Typography>
            <Typography className="sub_title dark_text mb_2 mt_1">
              By connecting your wallet, you agree to our{" "}
              <Link to={PATH.account}>Term of Service</Link> and our{" "}
              <Link to={PATH.account}>Privacy Policy</Link>
            </Typography>
            <Box className="mt_1">
              <Box className="wallet_button box_flex">
                <Box component="span" className="wallet_icon">
                  <MetaMask />
                </Box>
                <Typography componet="span">&nbsp; MetaMask</Typography>
              </Box>
            </Box>
            <Logo hasText />
          </CardContent>
        </Card>
      </Div>
    </Box>
  );
};

export default ConnectWallet;
