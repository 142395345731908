import React from "react";

import TitlePage from "app/components/TitlePage";

const BasicLayout = ({ header, children }) => {
  return (
    <div>
      {header && <TitlePage>{header}</TitlePage>}
      {children}
    </div>
  );
};

export default BasicLayout;
