import React from "react";

const usePageMeta = (title, description, image, type) => {
  const defaultTitle = "Cpc capital";

  React.useEffect(() => {
    document.title = title || defaultTitle;
    document.querySelector("meta[name='description']") &&
      document
        .querySelector("meta[name='description']")
        .setAttribute("content", description);
    document.querySelector("meta[property='og:title']") &&
      document
        .querySelector("meta[property='og:title']")
        .setAttribute("content", title || defaultTitle);
    document.querySelector("meta[property='og:type']") &&
      document
        .querySelector("meta[property='og:type']")
        .setAttribute("content", type);
    document.querySelector("meta[property='og:description']") &&
      document
        .querySelector("meta[property='og:description']")
        .setAttribute("content", description);
    document.querySelector("meta[name='twitter:description']") &&
      document
        .querySelector("meta[name='twitter:description']")
        .setAttribute("content", description);
    document.querySelector("meta[property='og:image']") &&
      document
        .querySelector("meta[property='og:image']")
        .setAttribute("content", image);
  }, [defaultTitle, title, description, image, type]);
};

export default usePageMeta;
