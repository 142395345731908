import { Box, Typography } from "@mui/material";

export default function NoData({ title = "Data Unavailable" }) {
  return (
    <Box mt={5}>
      <Typography
        sx={{
          fontSize: "24px",
          lineHeight: "36px",
          textAlign: "center",
          color: "#fff",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}
