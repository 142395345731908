import React from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import { ReactComponent as NotFoundImg } from "app/assets/images/undraw_page_not_found.svg";
import { Link } from "react-router-dom";
import PATH from "app/routes/path";
import {INSIGHT_HOST} from "app/utils/constants/paths";

const NotFound = () => {
  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(4),
      }}
    >
      <Div
        sx={{
          display: "inline-flex",
          mb: 3,
          "& svg": {
            width: "200px",
            height: "auto",
          },
        }}
      >
        <NotFoundImg />
      </Div>
      <Typography
        align={"center"}
        component={"h2"}
        variant={"h1"}
        mb={3}
        color="#fff"
      >
        Oops, an error has occurred. Page not found!
      </Typography>

      <Button
        variant="contained"
        component={Link}
        to={PATH.home}
        onClick={() => (window.location.href = INSIGHT_HOST)}
      >
        Go to home
      </Button>
    </Div>
  );
};

export default NotFound;
