import React from "react";
import { Typography, Box } from "@mui/material";
import Paragraph from "./Paragraph";
import Logo from "app/shared/Logo";

const Common = () => {
  return (
    <Box>
      <Box
        sx={{
          "& .logo_wrapper": {
            display: "flex",
            margin: "15px auto",
            justifyContent: "center",
            maxWidth: "480px",
          },
          "& .logo_wrapper img": {
            maxWidth: "80px",
          },
        }}
      >
        <Logo />
      </Box>
      <Typography
        component="h3"
        sx={{ fontSize: "36px", lineHeight: "60px", color: "#fff", p: 4 }}
      >
        Unlock the Power of Crypto
      </Typography>
      <Paragraph>
        <Typography>
          CPC Capital provides the power to maximize profits in cryptocurrency
        </Typography>
      </Paragraph>
      <Paragraph>
        <Typography>Providing real-time onchain data of tokens</Typography>
      </Paragraph>
      <Paragraph>
        <Typography>Providing analysis and insight to make money</Typography>
      </Paragraph>
      <Paragraph>
        <Typography>
          Providing the earliest crypto news and narrative
        </Typography>
      </Paragraph>
    </Box>
  );
};

export default Common;
