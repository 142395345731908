import React from 'react';
import './line.css';

const Line = (props) => {

    return (
        <div className='line-percentage'>
            <div className={`inner ${props.color ?? 'blue'}`} style={{'width': props.width}}></div>
        </div>
    );
};

export default Line;