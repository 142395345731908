import React from "react";
import Balance from "../pages/balance";
// import TokenGodMode from "../pages/tokenGodMode";
import TokenBubbles from "../pages/tokenBubbles";
import CEXTokenFlow from "../pages/cexTokenFlow";
import Plan from "../pages/plan";
import AccountMgt from "../pages/account";
import ConnectWallet from "../pages/connectWallet";
import Profile from "../pages/profile";
import Reports from "../pages/reports";
import ReportDetails from "../pages/reportDetails";
import Page from "@jumbo/shared/Page";
import PATH from "./path";

const dashboardRoutes = [
  {
    path: PATH.tokenDistribution,
    element: <Page component={Balance} isRedirect={true} />,
  },
  {
    path: PATH.tokenAnalytics,
    element: (
      <Page
        isRedirect={true}
        component={() => (
          <CEXTokenFlow title="sidebar.menuItem.tokenAnalysis" />
        )}
      />
    ),
  },
  {
    path: PATH.cexFlow,
    element: <Page component={CEXTokenFlow} isRedirect={true} />,
  },
  {
    path: PATH.tokenBubbles,
    // element: <Page isRedirect={true} component={() => <TokenBubbles />} />,
    element: (
      <Page
        isRedirect={true}
        component={() => <CEXTokenFlow title="sidebar.menuItem.tokenBubbles" />}
      />
    ),
  },
  {
    path: PATH.subcription,
    element: <Page component={Plan} isRedirect={true} />,
  },
  {
    path: PATH.account,
    element: <Page component={AccountMgt} isRedirect={true} />,
  },
  {
    path: PATH.profileDetails,
    element: <Page component={Profile} isRedirect={true} />,
  },
  {
    path: PATH.reports,
    element: <Page component={Reports} isRedirect={true} />,
  },
  {
    path: PATH.reportCateogries,
    element: <Page component={Reports} isRedirect={true} />,
  },
  {
    path: PATH.reportDetails,
    element: <Page component={ReportDetails} isRedirect={true} />,
  },

  {
    path: PATH.connectWallet,
    element: (
      <Page
        component={ConnectWallet}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
];

export default dashboardRoutes;
