import React from "react";
import PATH from "app/routes/path";
import { ReactComponent as Homepage } from "app/assets/images/Homepage.svg";
import { ReactComponent as News } from "app/assets/images/News_Alpha.svg";
import { ReactComponent as Analytics } from "app/assets/images/Analytics.svg";
import { ReactComponent as Category } from "app/assets/images/Category.svg";
import { ReactComponent as OnChain } from "app/assets/images/Onchain.svg";
import { ReactComponent as Dex } from "app/assets/images/Dex.svg";
import { ReactComponent as L1L2 } from "app/assets/images/l1l2.svg";
import { ReactComponent as AI } from "app/assets/images/AI.svg";
import { ReactComponent as Projects } from "app/assets/images/Projects.svg";
import { ReactComponent as Market } from "app/assets/images/Market.svg";
import { ReactComponent as Gamefi } from "app/assets/images/Gamefi.svg";
import { ReactComponent as Narrative } from "app/assets/images/Narrative.svg";
import { ReactComponent as Defi } from "app/assets/images/Defi.svg";
import { ReactComponent as RWA } from "app/assets/images/Home2.svg";
import { ReactComponent as Bubbles } from "app/assets/images/Token_Bubbles.svg";
import { ReactComponent as TokenAnalytics } from "app/assets/images/Token_Analysis.svg";
import { ReactComponent as Education } from "app/assets/images/Education.svg";
import { ReactComponent as Cex } from "app/assets/images/CEX_Flow.svg";
import { ReactComponent as Report } from "app/assets/images/Report.svg";
import { INSIGHT_HOST } from "app/utils/constants/paths";
const menus = [
  {
    label: "sidebar.menu.research",
    type: "section",
    children: [
      {
        uri: PATH.home,
        label: "sidebar.menuItem.home",
        type: "nav-item",
        icon: <Homepage title={"Home"} />,
      },
      {
        uri: `${INSIGHT_HOST}/category/news/`,
        label: "sidebar.menuItem.news",
        type: "nav-item",
        icon: <News title={"News"} />,
      },
      {
        uri: `${INSIGHT_HOST}/category/analytics/`,
        label: "sidebar.menuItem.analytics",
        type: "collapsible",
        icon: <Analytics title={"Analytics"} />,
        children: [
          {
            uri: `${INSIGHT_HOST}/category/analytics/market-update/`,
            label: "sidebar.menuItem.market",
            type: "nav-item",
            icon: <Market title="Market" />,
          },
          {
            uri: `${INSIGHT_HOST}/category/analytics/onchain-analytical/`,
            label: "sidebar.menuItem.onChain",
            type: "nav-item",
            icon: <OnChain title="onChain" />,
          },
          {
            uri: `${INSIGHT_HOST}/category/analytics/projects/`,
            label: "sidebar.menuItem.project",
            type: "nav-item",
            icon: <Projects title="Projects" />,
          },
        ],
      },
      {
        uri: `${INSIGHT_HOST}/category/categories/`,
        label: "sidebar.menuItem.categories",
        type: "collapsible",
        icon: <Category title={"Category"} />,
        children: [
          {
            uri: `${INSIGHT_HOST}/category/categories/ai/`,
            label: "sidebar.menuItem.ai",
            type: "nav-item",
            icon: <AI title="AI" />,
          },
          {
            uri: `${INSIGHT_HOST}/category/categories/defi/`,
            label: "sidebar.menuItem.defi",
            type: "nav-item",
            icon: <Defi title="defi" />,
          },
          {
            uri: `${INSIGHT_HOST}/category/categories/gamefi-nft/`,
            label: "sidebar.menuItem.gamefi",
            type: "nav-item",
            icon: <Gamefi title="gamefi" />,
          },
          {
            uri: `${INSIGHT_HOST}/category/categories/l1-l2/`,
            label: "sidebar.menuItem.l1l2",
            type: "nav-item",
            icon: <L1L2 title="L1L2" />,
          },
          {
            uri: `${INSIGHT_HOST}/category/categories/narrative/`,
            label: "sidebar.menuItem.narrative",
            type: "nav-item",
            icon: <Narrative title="AI" />,
          },
          {
            uri: `${INSIGHT_HOST}/category/categories/rwa/`,
            label: "sidebar.menuItem.rwa",
            type: "nav-item",
            icon: <RWA title="AI" />,
          },
        ],
      },
      // {
      //   uri: PATH.reports + "/6",
      //   label: "sidebar.menuItem.dex",
      //   type: "nav-item",
      //   icon: <Dex title={"Dex"} />,
      // },
      // {
      //   uri: PATH.reports + "/18",
      //   label: "sidebar.menuItem.onChain",
      //   type: "nav-item",
      //   icon: <OnChain title={"On Chain"} />,
      // },
    ],
  },
  {
    label: "sidebar.menu.application",
    type: "section",
    children: [
      {
        uri: PATH.tokenAnalytics,
        label: "sidebar.menuItem.tokenAnalysis",
        type: "nav-item",
        icon: <TokenAnalytics title={"Token Analytics"} />,
      },
      {
        uri: PATH.cexFlow,
        label: "sidebar.menuItem.cexFlow",
        type: "nav-item",
        icon: <Cex title={"Cex"} />,
      },
      {
        uri: PATH.tokenBubbles,
        label: "sidebar.menuItem.tokenBubbles",
        type: "nav-item",
        icon: <Bubbles title={"Bubbles"} />,
      },
    ],
  },
  {
    label: "sidebar.menu.knowledge",
    type: "section",
    children: [
      {
        uri: `${INSIGHT_HOST}/category/education/`,
        label: "sidebar.menuItem.education",
        type: "nav-item",
        icon: <Education title={"education"} />,
      },
      {
        uri: `${INSIGHT_HOST}/category/report/`,
        label: "sidebar.menuItem.reports",
        type: "nav-item",
        icon: <Report title={"Report"} />,
      },
    ],
  },
];

export default menus;
