import React from "react";
import { CommonHeader } from "./components/styled";
import BasicLayout from "app/layouts/content-layouts/BasicLayout";

import { useTranslation } from "react-i18next";
import Breadcrumb from "app/components/Breadcrumb";

const CEXTokenFlow = ({ title = "sidebar.menuItem.cexFlow" }) => {
  const { t } = useTranslation();
  return (
    <BasicLayout>
      <Breadcrumb data={[{ label: t(title), uri: "" }]} />
      <CommonHeader>{t("pages.title.comingSoon")}</CommonHeader>
    </BasicLayout>
  );
};

export default CEXTokenFlow;
