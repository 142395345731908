import React, { useState } from "react";
import { Alert, Card, CardContent, Box } from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import authServices from "../../../services/auth-services";
import PATH from "app/routes/path";
import Loading from "app/components/Loading";
import useQuery from "app/hooks/useQuery";
import Logo from "app/shared/Logo";
import { useSnackbar } from "notistack";

const validationSchema = yup.object({
  password: yup.string("Enter your password").required("Password is required"),
  password_confirmation: yup
    .string()
    .required("Password Confirmation is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const query = useQuery();

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const mutation = { isError: false };

  const onReset = async (password, password_confirmation) => {
    setLoading(true);
    const form = new FormData();
    form.append("email", query.get("email").replace(" ", "+"));
    form.append("token", query.get("token"));
    form.append("password", password);
    form.append("password_confirmation", password_confirmation);

    const { error } = await authServices.resetPassword(form);
    setLoading(false);
    if (error) {
      setErrorMessage(
        "Something wrong occurred while processing your password update. Try later!"
      );
      return;
    }
    enqueueSnackbar(
      "Your password has changed. Redirecting you to login page",
      {
        variant: "success",
      }
    );
    setTimeout(() => navigate(PATH.login), 1000);
  };

  return (
    <Box>
      <Box
        sx={{
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          "& .logo_wrapper": {
            m: 4,
          },
          "& .logo_wrapper img": {
            maxWidth: "100px",
          },
        }}
      >
        <Logo />

        <Div
          className="card_wrapper"
          sx={{
            flex: 1,
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: (theme) => theme.spacing(4),
            "& a": {
              color: "#A9F000",
            },
          }}
        >
          <Card
            sx={{ maxWidth: "100%", width: 360, mb: 4 }}
            className="card_form"
          >
            <CardContent sx={{ pt: 5 }}>
              {errorMessage && (
                <Alert sx={{ mb: 3 }} severity="error">
                  {errorMessage}
                </Alert>
              )}
              <Formik
                validateOnChange={true}
                initialValues={{
                  password: "",
                  password_confirmation: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                  setErrorMessage("");
                  setSubmitting(true);
                  onReset(data.password, data.password_confirmation);
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    {mutation.isError && <p>{mutation.error.message}</p>}
                    <Div sx={{ mb: 3, mt: 1 }}>
                      <JumboTextField
                        fullWidth
                        name="password"
                        label="New Password"
                        type="password"
                      />
                    </Div>
                    <Div sx={{ mb: 2, mt: 1 }}>
                      <JumboTextField
                        fullWidth
                        name="password_confirmation"
                        label="Password Confirmation"
                        type="password"
                      />
                    </Div>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3, mt: 1 }}
                      loading={isSubmitting || mutation.isLoading}
                    >
                      Reset
                    </LoadingButton>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Div>
      </Box>
      <Loading isLoading={isLoading} />
    </Box>
  );
};

export default ResetPassword;
