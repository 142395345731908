import { Box, Typography, styled } from "@mui/material";

export const Icon = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  background: "#A9F000",
  borderColor: "none",
  cursor: "pointer",
}));

export const Header = ({ icon, title, sx }) => (
  <Box className={`common_flex common_gap_20`}>
    {icon}
    <Typography
      sx={{ fontSize: "20px", lineHeight: "24px", color: "#ccc", ...sx }}
    >
      {title}
    </Typography>
  </Box>
);

export const TableRow = styled(Box)(({ theme }) => ({
  display: "grid",
  // gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gridTemplateColumns: "1fr 1fr",
  "&:hover": {
    opacity: "0.7",
  },
}));
