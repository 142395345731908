import React, { useCallback } from "react";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
// import NotificationsDropdown from "../../../../shared/NotificationsDropdown";
// import MessagesDropdown from "../../../../shared/MessagesDropdown";
import SearchGlobal from "../../../../shared/SearchGlobal";
import {
  IconButton,
  Slide,
  useMediaQuery,
  Button,
  Stack,
  Box,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../../shared/Logo";
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from "@jumbo/utils/constants";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import { useNavigate } from "react-router-dom";
import PATH from "app/routes/path";
// import LocalizationOptions from "app/shared/JumboCustomizer/components/LocalizationOptions";
import { ReactComponent as TeleIcon } from "app/assets/images/telegram.svg";
import { ReactComponent as Notifications } from "app/assets/images/Notifications.svg";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

const Header = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const [dropdownSearchVisibility, setDropdownSearchVisibility] =
    React.useState(false);
  const { headerTheme } = useJumboHeaderTheme();
  const storedToken = localStorage.getItem("token");
  const { setAuthToken, authUser } = useJumboAuth();

  const showDropdownSearch = useMediaQuery("(max-width:575px)");
  const navigate = useNavigate();

  const renderAuth = useCallback(
    (auth) => {
      if (auth) {
        return <AuthUserDropdown />;
      } else {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              sx={{
                padding: "8px 16px",
                borderRadius: "4px",
                background: "#A9F000",
                color: "#fff",
                border: "1px solid #A9F000",
                fontWeight: 400,
              }}
              onClick={() => navigate(PATH.signup)}
            >
              Sign up
            </Button>
            <Button
              sx={{
                padding: "8px 16px",
                borderRadius: "4px",
                color: "#ccc",
                border: "1px solid #A9F000",
                fontWeight: 400,
              }}
              onClick={() => navigate(PATH.login)}
            >
              Sign in
            </Button>
          </Stack>
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  return (
    <React.Fragment>
      {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
        sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
        (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT &&
          !sidebarOptions.open)) && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{
            ml:
              sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                ? -2
                : 0,
            mr: 3,
          }}
          onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}
        >
          {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
      )}
      {sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <Logo sx={{ mr: 3 }} mode={headerTheme.type ?? "light"} />
      )}
      {showDropdownSearch && (
        <Slide in={dropdownSearchVisibility}>
          <Div
            sx={{
              zIndex: 5,
              left: 0,
              right: 0,
              position: "absolute",
              height: "100%",
            }}
          >
            <SearchGlobal
              sx={{
                maxWidth: "none",
                height: "100%",
                display: "flex",

                "& .MuiInputBase-root": {
                  flex: 1,
                  borderRadius: 0,
                  background: (theme) => theme.palette.background.default,
                },
                "& .MuiInputBase-input": {
                  pr: 6,
                },
              }}
            />
            <IconButton
              sx={{
                position: "absolute",
                right: 15,
                top: "50%",
                color: "inherit",
                transform: "translateY(-50%)",
              }}
              onClick={() => setDropdownSearchVisibility(false)}
            >
              <CloseIcon />
            </IconButton>
          </Div>
        </Slide>
      )}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        width="100%"
      >
        {!showDropdownSearch && (
          <SearchGlobal
            sx={{
              maxWidth: { xs: 240, md: 320 },
            }}
          />
        )}
        {showDropdownSearch && (
          <JumboIconButton
            elevation={25}
            onClick={() => setDropdownSearchVisibility(true)}
          >
            <SearchIcon fontSize={"small"} />
          </JumboIconButton>
        )}
        <Logo hasText />
        <Box className="box_flex">
          <a
            alt="telegram"
            title="telegram"
            className="tele_icon has_fill"
            target="_blank"
            href={`https://t.me/cpccapital_bot${
              authUser?.telegram_verified_at
                ? ""
                : "?start=" + authUser?.tg_code
            }`}
            rel="noreferrer"
          >
            <TeleIcon />
          </a>
          <Box component="span" className="tele_icon no_fill">
            <Notifications />
            <Box component="span" className="red_dot"></Box>
          </Box>
          {renderAuth(storedToken)}
          {/* <LocalizationOptions /> */}
        </Box>
      </Stack>
    </React.Fragment>
  );
};

export default Header;
