import React from "react";
import { Form, Formik } from "formik";

import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import JumboAppTextField from "@jumbo/components/JumboAppTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import authServices from "app/services/auth-services";
import { lowercaseText } from "@jumbo/utils";
import { INSIGHT_HOST } from "app/utils/constants/paths";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
  password_confirmation: yup
    .string()
    .required("Password Confirmation is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  name: yup.string("Enter your name").required("Name is required"),
});

const SignupForm = ({ setLoading, setError }) => {
  const { setAuthToken, setAuthData } = useJumboAuth();

  const onSignup = async ({ name, email, password, password_confirmation }) => {
    setLoading && setLoading(true);

    const { response, error } = await authServices.signUp({
      name,
      email: lowercaseText(email),
      password,
      password_confirmation,
    });
    setLoading && setLoading(false);
    if (error) {
      setError("Your signup have a problem, Please try again");
      return;
    }

    setAuthToken(response.access_token);
    setAuthData({
      authToken: response.access_token,
      authUser: response.user,
      isLoading: false,
    });
    window.location.href = INSIGHT_HOST;
  };

  const mutation = { isError: false };
  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={{
          name: "",
          email: "",
          password: "",
          password_confirmation: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          const { name, email, password, password_confirmation } = data;
          onSignup({ name, email, password, password_confirmation });
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form noValidate autoComplete="off">
            <Div sx={{ mb: 3, mt: 3 }}>
              <JumboAppTextField fullWidth name="name" label="Name" />
            </Div>
            <Div sx={{ mb: 3, mt: 1 }}>
              <JumboAppTextField fullWidth name="email" label="Email" />
            </Div>
            <Div sx={{ mb: 2, mt: 1 }}>
              <JumboAppTextField
                fullWidth
                name="password"
                label="Password"
                type="password"
              />
            </Div>
            <Div sx={{ mb: 2, mt: 1 }}>
              <JumboAppTextField
                fullWidth
                name="password_confirmation"
                label="Password Confirmation"
                type="password"
              />
            </Div>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              sx={{ mb: 3 }}
              loading={isSubmitting || mutation.isLoading}
            >
              Signup
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignupForm;
