import jwtAuthAxios from "./auth/jwtAuth";

const authServices = {};

authServices.getCurrentUser = async () => {
  const { data } = await jwtAuthAxios.get("/auth");
  return data;
};

authServices.signIn = async (loginCreds) => {
  try {
    const { data } = await jwtAuthAxios.post("/auth/login", loginCreds);
    return { response: data?.data, error: null };
  } catch (e) {
    return { response: null, error: e };
  }
};

authServices.signInWithTelegram = async (loginCreds) => {
  try {
    const { data } = await jwtAuthAxios.post(
      "/auth/login/telegram",
      loginCreds
    );
    return { response: data?.data, error: null };
  } catch (e) {
    return { response: null, error: e };
  }
};

authServices.signUp = async (signUpPayLoad) => {
  try {
    const response = await jwtAuthAxios.post("/auth/register", signUpPayLoad);
    if (response.status === 200 || response.status === 201) {
      return { response: response.data.data, error: null };
    } else {
      return { response: null, error: response.data };
    }
  } catch (e) {
    return { response: null, error: e };
  }
};

authServices.forgotPassword = async (emailPayload) => {
  try {
    const { data } = await jwtAuthAxios.post(
      "/auth/forgot-password",
      emailPayload
    );
    return { response: data?.data, error: null };
  } catch (e) {
    return { response: null, error: e };
  }
};

authServices.forgotPassword = async (emailPayload) => {
  try {
    const { data } = await jwtAuthAxios.post(
      "/auth/forgot-password",
      emailPayload
    );
    return { response: data?.data, error: null };
  } catch (e) {
    return { response: null, error: e };
  }
};

authServices.resetPassword = async (paswordPayload) => {
  try {
    const { data } = await jwtAuthAxios.post(
      "/auth/reset-password",
      paswordPayload,
      {
        header: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { response: data?.data, error: null };
  } catch (e) {
    return { response: null, error: e };
  }
};

authServices.changePassword = async (paswordPayload) => {
  try {
    const { data } = await jwtAuthAxios.post(
      "/users/change-password",
      paswordPayload,
      {
        header: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return { response: data?.data, error: null };
  } catch (e) {
    return { response: null, error: e };
  }
};

authServices.me = async () => {
  try {
    const { data } = await jwtAuthAxios.get("/me");
    return { response: data?.data, error: null };
  } catch (e) {
    return { response: null, error: e };
  }
};

export default authServices;
