import React from "react";

import AccountMgt from "../pages/account";
import Page from "@jumbo/shared/Page";
import PATH from "./path";
import ChangePassword from "../pages/auth-pages/changePassword";

const authorizedRoutes = [
  {
    path: PATH.account,
    element: <Page component={AccountMgt} isRedirect={true} />,
  },
  {
    path: PATH.changePassword,
    element: (
      <Page component={ChangePassword} layout={"solo-page"} isRedirect={true} />
    ),
  },
];

export default authorizedRoutes;
