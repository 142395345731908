import React from "react";
import Div from "@jumbo/shared/Div";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import Loading from "app/components/Loading";

import SignupForm from "./components/SignupForm";
import Common from "./components/Common";
import PATH from "app/routes/path";
import { Alert } from "@mui/material";
import "./register.css";

const Signup1 = () => {
  const [isLoading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  return (
    <Box>
      <Box
        className="register_container"
        sx={{
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box className="register_wrapper">
          <Common />
          <Div
            className="card_wrapper"
            sx={{
              flex: 1,
              flexWrap: "wrap",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: (theme) => theme.spacing(4),
              "& a": {
                color: "#A9F000",
              },
            }}
          >
            <Card
              sx={{ maxWidth: "100%", width: 360, mb: 4 }}
              className="card_form"
            >
              <CardContent sx={{ pt: 0 }}>
                {errorMessage && (
                  <Alert sx={{ mt: 3 }} severity="error">
                    {errorMessage}
                  </Alert>
                )}
                <SignupForm
                  setLoading={setLoading}
                  setError={setErrorMessage}
                />
                <Typography textAlign={"center"} variant={"body1"} mb={1}>
                  Have an account? &nbsp;
                  <Link underline="none" to={PATH.login}>
                    Sign in
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </Div>
        </Box>
      </Box>
      <Loading isLoading={isLoading} />
    </Box>
  );
};

export default Signup1;
