const PATH = {
  tokenDistribution: "/application/token-distribution",
  tokenGoodMode: "/application/token-analysis",
  subcription: "/subscription-plans",
  cexFlow: "/application/cex-token-flow",
  tokenBubbles: "/application/token-bubbles",
  tokenAnalytics: "/application/token-analysis",
  reports: "/reports",
  reportCateogries: "/reports/:id",
  reportDetails: "/reports/:id/details/:idDetail",
  categories: "/categories",
  categoriesDetails: "/categories/:id",
  analytics: "/analytics",
  analyticsDetails: "/analytics/:id",
  dex: "/dex",
  education: "/education",
  educationDetails: "/education/:id",
  dexDetails: "/dex/:id",
  news: "/news",
  newsDetails: "/news/:id",
  profileDetails: "/profile",
  login: "/user/login",
  telegramLogin: "/login/telegram",
  signup: "/user/signup",
  reset: "/user/reset",
  resetPassword: "/reset-password",
  changePassword: "/change-password",
  account: "/account-management",
  tags: "/tags",
  connectWallet: "/application/connect-wallet",
  tagsDetail: "/tags/:id",
  home: "/",
};

export default PATH;
