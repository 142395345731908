import { USE_IMAGE_PLACEHOLDERS } from "./constants/paths";
import { MIL } from "./constants/constants";
import Web3 from "web3";
export const getAssetPath = (url, size) => {
  if (USE_IMAGE_PLACEHOLDERS) {
    return `https://via.placeholder.com/${size}.png`;
  }

  return url;
};

export const formatPriceForToken = (balance, decimals = 18) => {
  const balanceOf = Web3.utils.BN(balance) / 10 ** decimals;
  return new Intl.NumberFormat().format(balanceOf);
};

export const round = (num) => Math.round(num * 100) / 100;

export const formatPriceForLarge = (price) => {
  const num = new Intl.NumberFormat().format(price);
  return num;
};

export const roundWithLarge = (num) =>
  num >= MIL
    ? formatPriceForLarge(Math.round((num / MIL) * 100) / 100) + "M"
    : formatPriceForLarge(Math.round(num * 100) / 100);

export const normalFormat = (price) => {
  return new Intl.NumberFormat().format(price);
};

const converUnitTime = (unitTime) => {
  return new Date(unitTime * 1000);
};

export const dayAgo = (unitTime, str = "d ago") => {
  const day = converUnitTime(unitTime);
  const today = new Date();
  const differenceInTime = today.getTime() - day.getTime();
  const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
  return `${differenceInDays}${str}`;
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; Max-Age=0; path=/; domain=cpccapital.io";
};
