import React from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";

const Breadcrumb = ({ data }) => {
  return (
    <Box className="breadcrumb_item">
      {data.map((item, index) => {
        return data.length - 1 === index ? (
          <React.Fragment>
            <Typography
              component="span"
              key={index}
              className={"last_item breadcrumb"}
            >
              {item.label}
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography
              component={Link}
              to={item.uri}
              key={index}
              className={"breadcrumb"}
            >
              {item.label}
            </Typography>
            <KeyboardArrowRightIcon />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default Breadcrumb;
