import { Box } from "@mui/material";
import React, { useMemo, useCallback } from "react";
import BasicLayout from "app/layouts/content-layouts/BasicLayout";
import NoData from "app/components/NoData";
import { PostDetails } from "app/pages/reports/components/PostDetails";
import { useParams } from "react-router-dom";
import useFetch from "app/hooks/useFetch";
import Breadcrumb from "app/components/Breadcrumb";
import _ from "lodash";
import PATH from "app/routes/path";

import WithLoading from "app/components/WithLoading";

const ReportDetails = () => {
  const { id, idDetail } = useParams();

  const { isLoading, data } = useFetch(
    {
      path: `posts/${idDetail}`,
      options: {
        params: { _embed: "" },
      },
      dependent: [idDetail],
    },
    false
  );

  const { isLoading: isCategoryLoading, data: categories } = useFetch(
    {
      path: "categories",
      options: {
        params: { _embed: "", per_page: 100 },
      },
      dependent: [1],
    },
    false
  );

  const getCategory = useCallback(
    (category_id) => {
      if (categories) {
        const item = categories.find((it) => it.id === category_id);
        return {
          label: item && item.name && _.unescape(item.name),
          uri: PATH.reports + "/" + category_id,
          parent: item && item.parent,
        };
      } else return null;
    },
    [categories]
  );

  const getBreadCrumb = useMemo(() => {
    const breadcrumb = [];
    const item = getCategory(+id);
    if (item) {
      breadcrumb.unshift(item);
      if (item.parent) {
        breadcrumb.unshift(getCategory(item.parent));
      }
    }
    return breadcrumb;
  }, [getCategory, id]);

  return (
    <BasicLayout>
      <WithLoading isLoading={isLoading || isCategoryLoading}>
        <Breadcrumb data={getBreadCrumb} />
        <Box sx={{ width: "100%" }} className="post_page">
          {data ? (
            <Box mt={4} mb={4}>
              <PostDetails post={data} />
            </Box>
          ) : (
            <NoData />
          )}
        </Box>
      </WithLoading>
    </BasicLayout>
  );
};

export default ReportDetails;
