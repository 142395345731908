import React from "react";
import "./withLoading.css";

const Loading = () => (
  <div className="loader">
    <svg className="circular-loader" viewBox="25 25 50 50">
      <circle className="loader-path" cx="50" cy="50" r="20" />
    </svg>
  </div>
);

const WithLoading = ({ isLoading, children, hideChildren = true }) => {
  return (
    <div>
      {hideChildren ? (
        isLoading ? (
          <Loading />
        ) : (
          <div>{children}</div>
        )
      ) : isLoading ? (
        <div className="content_wrapper">
          <Loading />
          {children}
        </div>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
};

export default WithLoading;
