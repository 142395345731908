import React from "react";
import Login from "../pages/auth-pages/login";
import Signup1 from "../pages/auth-pages/signup1";
import ForgotPassword from "../pages/auth-pages/forgotPassword";
import PATH from "app/routes/path";

import Page from "@jumbo/shared/Page";
import homeRoutes from "./homeRoutes";
import authorizedRoutes from "./authorizedRoutes";
import Home from "../pages/home";
import NotFound from "../pages/NotFound";
import ResetPassword from "app/pages/auth-pages/resetPassword/ResetPassword";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [...homeRoutes];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
  {
    path: "/",
    element: <Page component={Home} isRedirect={true} />,
  },
  ...authorizedRoutes,
  ...homeRoutes,
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
  {
    path: PATH.signup,
    element: (
      <Page component={Signup1} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
  {
    path: PATH.login,
    element: (
      <Page component={Login} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
  {
    path: PATH.telegramLogin,
    element: (
      <Page component={Login} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
  {
    path: PATH.reset,
    element: (
      <Page
        component={ForgotPassword}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: PATH.resetPassword,
    element: (
      <Page
        component={ResetPassword}
        layout={"solo-page"}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: "*",
    element: (
      <Page component={NotFound} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
};
