import { Box, Typography } from "@mui/material";
import React from "react";
import BasicLayout from "app/layouts/content-layouts/BasicLayout";
import { CustomCard } from "./components/Card";
import { Course } from "./components/Course";
import useFetch from "app/hooks/useFetch";
import WithLoading from "app/components/WithLoading";
import "./styles.css";
import Slider from "react-slick";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";

const Plan = () => {
  const handleBuy = (plan) => {
    // TODO
  };
  const [nav1, setNav1] = React.useState(null);

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [slider1, setSlider1] = React.useState(0);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const { isLoading, data } = useFetch({
    path: "subscriptions/plans",
    options: {
      params: {},
    },
    dependent: 1,
  });

  const mockedData = [
    {
      id: 1,
      plan: 1,
      name: "3 months",
      target: "For crypto investors",
      price: 90,
      description:
        "Access custom labels and powerful crypto analytics that top funds use for a fraction of the cost.",
      disclaimer: [
        "Miễn phí 1 tháng membership",
        "Report hàng tuần",
        "Group tele member",
      ],
    },
    {
      id: 2,
      plan: 2,
      name: "1 year",
      target: "For professional investors",
      price: 250,
      description:
        "Win with professional insights and customized workflows. Everything in Standard, plus:",
      disclaimer: [
        "Miễn phí 1 tháng membership",
        "Voucher 10% ",
        "Future Airdrop",
      ],
    },
    {
      id: 3,
      plan: 3,
      name: "6 months",
      target:
        "For OG pioneers, crypto mavericks, and leaders. Annual commitment required",
      price: 150,
      description:
        "Join the most exclusive, curated community of crypto pioneers. Connect & learn with the best, nurture connections, and find what's next ahead of others. Everything in Professional, plus",
      disclaimer: [
        "Miễn phí 1 tháng membership",
        "Voucher 10% ",
        "Future Airdrop",
      ],
    },
  ];

  const courseData = [
    {
      id: 1,
      name: "K3: Crypto Mindset",
      place: "Zoom",
      time: " 1/1/2024 - 2/2/2024",
      registration_time: "15/12/2023",
    },
    {
      id: 2,
      name: "K3: Crypto Mindset",
      place: "Zoom",
      time: " 1/1/2024 - 2/2/2024",
      registration_time: "15/12/2023",
    },
    {
      id: 3,
      name: "K3: Crypto Mindset",
      place: "Zoom",
      time: " 1/1/2024 - 2/2/2024",
      registration_time: "15/12/2023",
    },
    {
      id: 4,
      name: "K3: Crypto Mindset",
      place: "Zoom",
      time: " 1/1/2024 - 2/2/2024",
      registration_time: "15/12/2023",
    },
    {
      id: 5,
      name: "K3: Crypto Mindset",
      place: "Zoom",
      time: " 1/1/2024 - 2/2/2024",
      registration_time: "15/12/2023",
    },
    {
      id: 5,
      name: "K3: Crypto Mindset",
      place: "Zoom",
      time: " 1/1/2024 - 2/2/2024",
      registration_time: "15/12/2023",
    },
  ];
  const settings = {
    speed: 500,
    slidesToShow: 1,
    onReInit: () => setCurrentSlide(slider1?.innerSlider.state.currentSlide),
    lazyLoad: true,
    arrows: false,
    // centerMode: true,
  };

  const menu = [
    {
      id: 1,
      name: "All in one",
    },
    {
      id: 2,
      name: "On-chain",
    },
    {
      id: 3,
      name: "Khóa học",
    },
    {
      id: 4,
      name: "Report",
    },
  ];
  React.useEffect(() => {
    setNav1(slider1);
  }, [slider1]);
  const CardComp = () => (
    <Box pb={4}>
      <Box className="plan_subcription_container">
        {data &&
          data.map((card, indx) => (
            <CustomCard
              {...{ ...mockedData[indx], ...card }}
              onBuy={() => handleBuy(card.id)}
            />
          ))}
      </Box>
    </Box>
  );
  const CourseComp = () => (
    <Box className="course_container">
      <Box className="course_wrapper">
        {courseData.map((course, idx) => (
          <Box
            key={idx}
            className={
              activeIndex === idx ? " course_item active" : "course_item"
            }
            onClick={() => setActiveIndex(idx)}
          >
            <Course {...course} />
          </Box>
        ))}
      </Box>
      <Box className="course_bottom"></Box>
    </Box>
  );
  return (
    <BasicLayout>
      <WithLoading isLoading={isLoading}>
        <Box sx={{ textAlign: "center", paddingBottom: 0, marginTop: 4 }}>
          <Typography
            component="h3"
            fontSize="36px"
            fontFamily={`TiktokDisplay`}
            mb={3}
          >
            Price Plan
          </Typography>
          <Typography fontSize="18px" fontFamily={`TiktokDisplay`} mb={3}>
            Lựa chọn gói phù hợp với bạn, nếu chưa có tài khoản, vui lòng
            <Typography component="span" display={"block"} color="#A9F000">
              đăng ký tại đây
            </Typography>
          </Typography>

          <Box className="plan_wrapper" mt={3} mb={3}>
            {menu.map((item, idx) => (
              <Box
                key={item.id}
                className={
                  currentSlide === idx
                    ? "active plan_thumbnail_item"
                    : "plan_thumbnail_item"
                }
                onClick={() => {
                  slider1?.slickGoTo(idx);
                }}
              >
                {item.name}
              </Box>
            ))}
            <Box component="span" className="plan_bookmark">
              <BookmarkAddedIcon />
            </Box>
          </Box>
          <Slider
            {...settings}
            asNavFor={nav1}
            ref={(slider) => setSlider1(slider)}
          >
            <CardComp />
            <CardComp />
            <CourseComp />
            <CardComp />
          </Slider>

          <Box padding="20px 0" borderTop="1px solid #ccc">
            <Typography fontSize="18px" fontFamily={`TiktokDisplay`} mb={3}>
              Bạn là người mới? Hãy xem hướng dẫn nạp tiền
              <Typography component="span" color="#A9F000">
                tại đây
              </Typography>
            </Typography>
          </Box>
        </Box>
      </WithLoading>
    </BasicLayout>
  );
};

export default Plan;
