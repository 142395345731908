import { Card, Box, Button, Typography } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const CustomCard = ({
  name,
  plan,
  target,
  price,
  description,
  disclaimer,
  onBuy,
  timeline,
  id,
}) => {
  return (
    <Card
      sx={{
        p: 4,
        borderRadius: "18px",
        background: "#17181A 0% 0% no-repeat padding-box",
        minHeight: "564px",
      }}
    >
      <Typography
        mb={2}
        color="#a5a5a5"
        fontSize="26px"
        sx={{ textTransform: "capitalize" }}
      >
        {timeline ?? name}
        <br />
        Membership
      </Typography>
      <Typography mb={2} fontSize={`56px`} color="#fff">
        ${Math.round(price)} USDT
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          margin: "20px 0 120px",
        }}
      >
        {disclaimer.map((it, idx) => (
          <Typography
            key={idx}
            color="#ccc"
            fontSize="18px"
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              margin: "0 0 15px",
              "& svg *": {
                fill: "#A9F000",
              },
            }}
          >
            <CheckCircleIcon color="#A9F000" />
            <Typography fontSize="">{it}</Typography>
          </Typography>
        ))}
      </Box>

      <Button
        onClick={() => onBuy(id)}
        mb={3}
        sx={{
          width: "100%",
          padding: "10px 20px",
          borderRadius: "28px",
          color: "#101214",
          fontWeight: 500,
          background: "#A9F000",
          textTransform: "capitalize",
          "&:hover": {
            background: "#303030 0% 0% no-repeat padding-box",
            color: "#ccc",
          },
        }}
      >
        Pay with crypto
      </Button>
    </Card>
  );
};
