import { HOST } from "app/utils/constants/paths";
import baseAxios from "axios";
import { getAuthToken } from "./auth/jwtAuth";

const axios = baseAxios.create({
  baseURL: HOST,
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.request.use(
  function (config) {
    const token = getAuthToken();
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;
