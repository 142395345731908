import React, { useState } from "react";
import { Alert, Card, CardContent, Box, Typography } from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import authServices from "../../../services/auth-services";
import Loading from "app/components/Loading";
import { lowercaseText } from "@jumbo/utils";
import Logo from "app/shared/Logo";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const ForgotPassword = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const mutation = { isError: false };

  const onForgotPassword = async (email) => {
    setLoading(true);
    const { error } = await authServices.forgotPassword({
      email: lowercaseText(email),
    });
    setLoading(false);
    if (error) {
      setErrorMessage(
        "Something wrong occurred while processing your request. Try it later!"
      );
      return;
    }
    setSuccess(true);
  };

  return (
    <Box>
      <Box
        sx={{
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          "& .logo_wrapper": {
            m: 4,
          },
          "& .logo_wrapper img": {
            maxWidth: "100px",
          },
        }}
      >
        <Logo />
        <Box sx={{ maxWidth: "400px", margin: "0 auto", textAlign: "center" }}>
          <Typography component="h2" fontSize={24}>
            Forgot your password ?
          </Typography>
          <Typography component="h3" fontSize={20}>
            Enter your email here and CPC will send you instruction to create a
            new one
          </Typography>
        </Box>
        <Div
          className="card_wrapper"
          sx={{
            flex: 1,
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: (theme) => theme.spacing(4),
            "& a": {
              color: "#A9F000",
            },
          }}
        >
          {isSuccess ? (
            <Box
              sx={{
                p: 3,
                borderRadius: "8px",
                border: "1px solid #fff",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                maxWidth: "800px",
              }}
            >
              <DoneOutlineIcon sx={{ color: "#ccc", fontSize: "24px" }} />
              <Typography>
                Please check your inbox, including the junk/spam folder, for an
                email from
                <Typography component="span" color="#A9F000" pl={1}>
                  no-repply@cpccapital.io
                </Typography>
                . To consistently receive notifications regarding your account
                details, consider whitelisting this address
              </Typography>
            </Box>
          ) : (
            <Card
              sx={{ maxWidth: "100%", width: 360, mb: 4 }}
              className="card_form"
            >
              <CardContent sx={{ pt: 5 }}>
                {errorMessage && (
                  <Alert sx={{ mb: 3 }} severity="error">
                    {errorMessage}
                  </Alert>
                )}

                <Formik
                  validateOnChange={true}
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(data, { setSubmitting }) => {
                    setErrorMessage("");
                    setSubmitting(true);
                    onForgotPassword(data.email);
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form
                      style={{ textAlign: "left" }}
                      noValidate
                      autoComplete="off"
                    >
                      {mutation.isError && <p>{mutation.error.message}</p>}
                      <Div sx={{ mb: 3, mt: 1 }}>
                        <JumboTextField fullWidth name="email" label="Email" />
                      </Div>

                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3, mt: 1 }}
                        loading={isSubmitting || mutation.isLoading}
                      >
                        Reset
                      </LoadingButton>
                    </Form>
                  )}
                </Formik>
              </CardContent>
            </Card>
          )}
        </Div>
      </Box>
      <Loading isLoading={isLoading} />
    </Box>
  );
};

export default ForgotPassword;
