import { config } from "../../../app/config/main";

export const storeToken = (token) => {
  const now = new Date();
  const current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  document.cookie =
    "token=" +
    (token || "") +
    "; expires=" +
    current.toUTCString() +
    "; path=/; domain=cpccapital.io";
  localStorage.setItem("token", token);

  if (!config?.authSetting?.axiosObject)
    throw Error(
      "axiosObject need to be set under authSettings inside app/config/main.js"
    );
  else
    config.authSetting.axiosObject.defaults.headers.common["Authorization"] =
      "Bearer " + token;
};

export const removeToken = () => {
  localStorage.removeItem("token");
  if (!config?.authSetting?.axiosObject)
    throw Error(
      "axiosObject need to be set under authSettings inside app/config/main.js"
    );
  else
    delete config.authSetting.axiosObject.defaults.headers.common[
      "Authorization"
    ];
};

export const saveAuthUser = (user) => {
  try {
    localStorage.setItem("user", JSON.stringify(user));
  } catch (error) {
    console.log("Error with storing user info");
  }
};

export const removeAuthUser = () => {
  localStorage.removeItem("user");
};

export const getAuthUser = () => {
  try {
    return JSON.parse(localStorage.getItem("user"));
  } catch (error) {
    console.log("Error with storing user info");
    return null;
  }
};
