import { Card, styled } from "@mui/material";

export const CustomCard = styled(Card)(({ theme }) => ({
  "& .MuiCardContent-root": {
    padding: "0",
    position: "relative",
    paddingBottom: "60px !important",
    width: "100%",
  },
  "& .clipped-content": {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "40px",
    marginBottom: "16px",
  },
  "& a, & a:hover": {
    textDecoration: "none",
    color: "inherit",
  },
  "& .button_wrapper": {
    display: "flex",
    justifyContent: "flex-start",
    position: "absolute",
    left: "16px",
    bottom: "20px",
    "& svg": {
      marginRight: "5px",
    },
  },
  // "& .post_title": {
  //   height: "60px",
  // },
}));

export const HorizontalCustomCard = styled(Card)(({ theme }) => ({
  "& .MuiCardContent-root": {
    padding: "0 !important",
    position: "relative",
    width: "100%",
  },
  "& .MuiCardMedia-root": {
    position: "absolute",
    top: 0,
    left: 0,
    right: "0",
    bottom: 0,
  },
  "& .clipped-content": {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "40px",
    marginBottom: "16px",
  },
  "& a, & a:hover": {
    textDecoration: "none",
    color: "inherit",
  },
  "& .button_wrapper": {
    display: "flex",
    justifyContent: "flex-start",

    "& svg": {
      marginRight: "5px",
    },
  },
  // "& .post_title": {
  //   height: "60px",
  // },
}));
