import { useState } from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableRow,
  Stack,
  TablePagination,
  TableHead,
} from "@mui/material";
import Line from "app/components/Line";
import { dayAgo, formatPriceForToken, round } from "app/utils/appHelpers";
import WithLoading from "app/components/WithLoading";
import Div from "@jumbo/shared/Div";
import {useTranslation} from "react-i18next";

import useFetch from "app/hooks/useFetch";

const TokenDistribution = ({ contractDetail }) => {
  const { t } = useTranslation();
  const headCells = [
    {
      id: "name",
      isSort: true,
      numeric: false,
      disablePadding: true,
      label: t('pages.tokenDistribution.list.name'),
    },
    {
      id: "balance",
      isSort: true,
      numeric: true,
      disablePadding: false,
      label: t('pages.tokenDistribution.list.balance'),
    },
    {
      id: "ownership",
      isSort: true,
      numeric: true,
      disablePadding: false,
      label: `% ${t('pages.tokenDistribution.list.ownership')}`,
    },
    {
      id: "change7d",
      isSort: true,
      numeric: true,
      disablePadding: false,
      label: "Change 7D",
    },
    {
      id: "change30d",
      isSort: true,
      numeric: true,
      disablePadding: false,
      label: "Change 30D",
    },
    {
      id: "recevied",
      isSort: true,
      numeric: true,
      disablePadding: false,
      label: t('pages.tokenDistribution.list.received'),
    },
    {
      id: "sent",
      isSort: true,
      numeric: true,
      disablePadding: false,
      label: t('pages.tokenDistribution.list.sent'),
    },
    {
      id: "first_in",
      isSort: true,
      numeric: true,
      disablePadding: false,
      label: t('pages.tokenDistribution.list.firstIn'),
    },
  ];

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sorts, setSorts] = useState({
    name: 'asc',
    balance: 'asc',
    ownership: 'asc',
    change7d: 'asc',
    change30d: 'asc',
    recevied: 'asc',
    sent: 'asc',
    first_in: 'asc',
  })

  const query = new URLSearchParams(window.location.search);

  const sortBy = (sortsNew) => {
    let result = '';
    const length = Object.keys(sortsNew).length;
    const lastKey = Object.keys(sortsNew)[length - 1];
    for (const [key, value] of Object.entries(sortsNew)) {
      const stringConcatenation = key === lastKey ? '' : ',';
      const typeSearch = value === 'asc' ? '' : '-';
      result += `${typeSearch}${key}`.concat(stringConcatenation);
    }
    return result;
  }

  const { isLoading, data: tops } = useFetch({
    path: "contracts/top-balances",
    options: {
      params: {
        page,
        limit: perPage,
        contract_address: query.get("contract_address"),
        sort_by: sortBy(sorts)
      },
    },
    dependent: [page, perPage, sorts],
  });
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangePerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const createSortHandler = (property) => (_event) => {
    const typeSearch = sorts[property] === 'asc' ? 'desc' : 'asc';
    setSorts((prevSorts) => {
      return {
        ...prevSorts,
        [property]: typeSearch
      }
    })
  };

  return (
    <div className="top-balance">
      <Paper elevation={3}>
        <Div sx={{ p: 3, fontSize: 20 }}>{t('pages.tokenDistribution.title')}</Div>
      </Paper>

      <Paper sx={{ width: "100%", mb: 2, mt: 3 }}>
        <WithLoading isLoading={isLoading}>
          <TableContainer>
            <Table sx={{ maxWidth: "100%" }}>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      sx={{ px: 0.5 }}
                      align={headCell.numeric ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}
                    >
                      <TableSortLabel
                        active={headCell.isSort}
                        direction={sorts[headCell.id]} 
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {tops?.data?.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell sx={{ px: 1, maxWidth: "25%" }}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Div sx={{ pl: 1 }}>
                            {tops?.attributes?.accounts[row.account_id]?.name ||
                              row.account_id}
                          </Div>
                        </Stack>
                      </TableCell>

                      <TableCell sx={{ px: 0.5 }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <Div>
                            {formatPriceForToken(
                              row.balance,
                              contractDetail &&
                                contractDetail[0] &&
                                contractDetail[0].decimals
                            )}
                          </Div>
                          <Div>{<Line width="40%" />}</Div>
                        </Stack>
                      </TableCell>

                      <TableCell sx={{ px: 0.5 }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          {round(row.percent_ownership)}%
                        </Stack>
                      </TableCell>

                      <TableCell sx={{ px: 0.5 }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <Div>
                            {formatPriceForToken(
                              row.change_7d,
                              contractDetail &&
                                contractDetail[0] &&
                                contractDetail[0].decimals
                            )}
                          </Div>
                          <Div>{<Line width="0%" />}</Div>
                        </Stack>
                      </TableCell>

                      <TableCell sx={{ px: 0.5 }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <Div>
                            {formatPriceForToken(
                              row.change_30d,
                              contractDetail &&
                                contractDetail[0] &&
                                contractDetail[0].decimals
                            )}
                          </Div>
                          <Div>{<Line width="60%" />}</Div>
                        </Stack>
                      </TableCell>

                      <TableCell sx={{ px: 0.5 }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <Div>
                            {formatPriceForToken(
                              row.first_received_at,
                              contractDetail &&
                                contractDetail[0] &&
                                contractDetail[0].decimals
                            )}
                          </Div>
                          <Div>{<Line width="60%" />}</Div>
                        </Stack>
                      </TableCell>

                      <TableCell sx={{ px: 0.5 }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <Div>
                            {formatPriceForToken(
                              row.first_received_at,
                              contractDetail &&
                                contractDetail[0] &&
                                contractDetail[0].decimals
                            )}
                          </Div>
                          <Div>{<Line width="30%" />}</Div>
                          <Div>{dayAgo(row.first_received_at)}</Div>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tops?.total || 0}
            rowsPerPage={tops?.per_page || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePerPage}
            page={tops?.current_page || 0}
          />
        </WithLoading>
      </Paper>
    </div>
  );
};

export default TokenDistribution;
