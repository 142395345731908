import React, { useEffect, useMemo, useState } from "react";
import { Alert, Card, CardContent, Typography, Box } from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import authServices from "../../../services/auth-services";
import PATH from "app/routes/path";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "app/components/Loading";
import { lowercaseText } from "@jumbo/utils";
import Common from "../signup1/components/Common";
import { INSIGHT_HOST } from "app/utils/constants/paths";
import useQuery from "app/hooks/useQuery";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login1 = () => {
  const { setAuthToken, setAuthData } = useJumboAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const mutation = { isError: false };
  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  const isLoginTele = useMemo(
    () => location.pathname === PATH.telegramLogin,
    [location.pathname]
  );

  const onSignIn = async (email, password) => {
    setLoading(true);
    const { response, error } = await authServices.signIn({
      email: lowercaseText(email),
      password,
    });
    setLoading(false);
    handleAuthRes(error, response);
  };

  const signInWithTelegram = async () => {
    setLoading(true);
    const { response, error } = await authServices.signInWithTelegram({
      telegram_login_code: query.get("code"),
    });
    setLoading(false);
    handleAuthRes(error, response);
  };

  const handleAuthRes = (error, response) => {
    if (error) {
      setErrorMessage("Your credentials do not match");
      if (isLoginTele) {
        navigate(PATH.login);
      }
      return;
    }
    setAuthToken(response.access_token);
    setAuthData({
      authToken: response.access_token,
      authUser: response.user,
      isLoading: false,
    });
    window.location.href = INSIGHT_HOST;
  };
  useEffect(() => {
    if (isLoginTele) {
      signInWithTelegram();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box
        className="register_container"
        sx={{
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box className="register_wrapper">
          <Common />
          <Div
            className="card_wrapper"
            sx={{
              flex: 1,
              flexWrap: "wrap",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: (theme) => theme.spacing(4),
              "& a": {
                color: "#A9F000",
              },
            }}
          >
            <Card
              sx={{ maxWidth: "100%", width: 360, mb: 4 }}
              className="card_form"
            >
              <CardContent sx={{ pt: 5 }}>
                {errorMessage && (
                  <Alert sx={{ mb: 3 }} severity="error">
                    {errorMessage}
                  </Alert>
                )}
                {!isLoginTele && (
                  <Formik
                    validateOnChange={true}
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(data, { setSubmitting }) => {
                      setErrorMessage("");
                      setSubmitting(true);
                      onSignIn(data.email, data.password);
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form
                        style={{ textAlign: "left" }}
                        noValidate
                        autoComplete="off"
                      >
                        {mutation.isError && <p>{mutation.error.message}</p>}
                        <Div sx={{ mb: 3, mt: 1 }}>
                          <JumboTextField
                            fullWidth
                            name="email"
                            label="Email"
                          />
                        </Div>
                        <Div sx={{ mb: 2, mt: 1 }}>
                          <JumboTextField
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                          />
                        </Div>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ mb: 3, mt: 1 }}
                          loading={isSubmitting || mutation.isLoading}
                        >
                          Login
                        </LoadingButton>
                        <Typography
                          textAlign={"center"}
                          variant={"body1"}
                          mb={1}
                        >
                          Don't have an account?
                          <Link underline="none" to={PATH.signup}>
                            &nbsp; Sign up now
                          </Link>
                        </Typography>
                        <Typography
                          textAlign={"center"}
                          variant={"body1"}
                          mb={1}
                        >
                          Forgot your password?
                          <Link underline="none" to={PATH.reset}>
                            &nbsp; Recover Now
                          </Link>
                        </Typography>
                      </Form>
                    )}
                  </Formik>
                )}
              </CardContent>
            </Card>
          </Div>
        </Box>
      </Box>
      <Loading isLoading={isLoading} />
    </Box>
  );
};

export default Login1;
