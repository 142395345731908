import React from "react";
import { TableRow } from "./styled";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { formatPriceForToken } from "app/utils/appHelpers";
import NoIcon from "app/assets/images/no-icon.jpg";

export const ProfileTable = ({ data }) => {
  return (
    <Box>
      <TableRow>
        <Box className="table_header">Token</Box>
        {/* <Box className="table_header">Price</Box> */}
        <Box className="table_header" sx={{ textAlign: "right" }}>
          Amount
        </Box>
        {/* <Box className="table_header" sx={{ textAlign: "right" }}>
          USD Value
        </Box> */}
      </TableRow>
      {data.map((item, idx) => (
        <TableRow key={idx}>
          <Box className="table_body">
            <Box
              className="common_flex"
              sx={{
                gap: "10px",
                "& a": {
                  color: "inherit",
                  textDecoration: "none",
                },
                "& a:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              <img src={item.logo ? item.logo : NoIcon} alt={item.name} />
              <Typography variant="span">
                <Link to={"/"}>{item.symbol}</Link>
              </Typography>
            </Box>
          </Box>
          {/* <Box className="table_body">${normalFormat(item.price)}</Box> */}
          <Box className="table_body" sx={{ textAlign: "right" }}>
            {formatPriceForToken(item.balance)}
          </Box>
          {/* <Box className="table_body" sx={{ textAlign: "right" }}>
            ${normalFormat(item.value)}
          </Box> */}
        </TableRow>
      ))}
    </Box>
  );
};
