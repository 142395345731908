import React from "react";
import { CardContent, Typography, Button, CardMedia, Box } from "@mui/material";
import PATH from "app/routes/path";
import { Link } from "react-router-dom";
import { CustomCard, HorizontalCustomCard } from "./styled";
import NoImg from "app/assets/images/no_img.jpg";
import { ReactComponent as ReadMore } from "app/assets/images/ReadMore.svg";

export function Post({ post, type, isColumn = true }) {
  return isColumn ? (
    <CustomCard className="post_item">
      <CardContent
        sx={{
          pt: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        component={Link}
        to={PATH.reports + "/" + type + "/details/" + post.id}
      >
        <Box
          sx={{
            mb: 1,
            width: "100%",
          }}
        >
          <CardMedia
            sx={{ width: "100%", height: "200px" }}
            alt={`${post.title && post.title.rendered}`}
            image={
              post._embedded &&
              Array.isArray(post._embedded["wp:featuredmedia"]) &&
              post._embedded["wp:featuredmedia"][0]
                ? post._embedded["wp:featuredmedia"][0].source_url
                : NoImg
            }
          />
        </Box>
        <Box p={1.5} sx={{ width: "100%" }}>
          <Typography
            variant={"h6"}
            mb={0.5}
            fontSize={24}
            color="#fff"
            className="post_title"
          >
            {post.title && post.title.rendered}
          </Typography>
          <Box
            className="clipped-content"
            color="inherit"
            dangerouslySetInnerHTML={{
              __html: post.excerpt && post.excerpt.rendered,
            }}
          ></Box>
          <Box className="button_wrapper">
            <Button
              className="button_post"
              component={Link}
              to={PATH.reports + "/" + type + "/details/" + post.id}
            >
              <ReadMore /> Read more
            </Button>
          </Box>
        </Box>
      </CardContent>
    </CustomCard>
  ) : (
    <HorizontalCustomCard>
      <CardContent
        className="post_item_slide"
        component={Link}
        to={PATH.reports + "/" + type + "/details/" + post.id}
      >
        <Box
          sx={{
            width: "100%",
            position: "relative",
          }}
        >
          <CardMedia
            alt={`${post.title && post.title.rendered}`}
            image={
              post._embedded &&
              Array.isArray(post._embedded["wp:featuredmedia"]) &&
              post._embedded["wp:featuredmedia"][0]
                ? post._embedded["wp:featuredmedia"][0].source_url
                : NoImg
            }
          />
        </Box>
        <Box className="pad_1">
          <Typography
            variant={"h6"}
            mb={0.5}
            fontSize={24}
            color="#fff"
            className="post_title"
          >
            {post.title && post.title.rendered}
          </Typography>
          <Box
            className="clipped-content"
            color="inherit"
            dangerouslySetInnerHTML={{
              __html: post.excerpt && post.excerpt.rendered,
            }}
          ></Box>
          <Box className="button_wrapper">
            <Button
              className="button_post"
              component={Link}
              to={PATH.reports + "/" + type + "/details/" + post.id}
            >
              <ReadMore /> Read more
            </Button>
          </Box>
        </Box>
      </CardContent>
    </HorizontalCustomCard>
  );
}
