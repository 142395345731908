import React from "react";
import Div from "@jumbo/shared/Div";
import { Link } from "react-router-dom";
import logo from "app/assets/images/Logo_Notext.svg";
import logoWithText from "app/assets/images/Logo_Text.svg";
import { INSIGHT_HOST } from "app/utils/constants/paths";

const Logo = ({ hasText = false, mini, mode, sx }) => {
  return (
    <Div
      className="logo_wrapper"
      sx={{
        display: "inline-flex",
        ...sx,
        "& img": {
          maxWidth: mini ? "70px" : "300px",
          width: hasText ? "300px" : "auto",
        },
      }}
    >
      <Link to={"/"} onClick={() => (window.location.href = INSIGHT_HOST)}>
        {!mini ? (
          <img src={hasText ? logoWithText : logo} alt="Logo" />
        ) : (
          <img src={hasText ? logoWithText : logo} alt="Logo" />
        )}
      </Link>
    </Div>
  );
};

Logo.defaultProps = {
  mode: "light",
};

export default Logo;
