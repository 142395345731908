import React from "react";
import { useNavigate } from "react-router-dom";
import {
  routesForAuthenticatedOnly,
  routesForNotAuthenticatedOnly,
} from "../../../app/routes";
import useRoutePathMatch from "@jumbo/hooks/useRoutePathMatch";
import {
  removeToken,
  storeToken,
  saveAuthUser,
  removeAuthUser,
  getAuthUser,
} from "./authHelpers";
import { config } from "../../../app/config/main";
import { AuthContext } from "@jumbo/components/JumboAuthProvider/JumboAuthContext";

const storedToken = localStorage.getItem("token");
let firstTimePageLoad = true;

const init = () => {
  let authUser = getAuthUser() ?? null;

  if (!config?.authSetting) {
    throw Error(
      `You are using JumboAuthProvider but you haven't setup authSetting inside /src/app/config/main.js's config object`
    );
  }

  if (storedToken) {
    storeToken(storedToken); // also sets axios header
  }

  return {
    authToken: storedToken ?? null,
    authUser: authUser,
    isLoading: false,
    fallbackPath: config.authSetting.fallbackPath,
  };
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "set-auth-data":
      return {
        ...state,
        ...action.payload,
      };

    case "start-loading":
      return {
        ...state,
        isLoading: true,
      };

    case "stop-loading":
      return {
        ...state,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};

const JumboAuthProvider = ({ children, ...restProps }) => {
  const [authOptions, setAuthOptions] = React.useReducer(
    authReducer,
    restProps,
    init
  );
  const [logout, setLogout] = React.useState(false);
  const navigate = useNavigate();
  const isAuthenticatedRouteOnly = useRoutePathMatch(
    routesForAuthenticatedOnly
  );
  const isNotAuthenticatedRouteOnly = useRoutePathMatch(
    routesForNotAuthenticatedOnly
  );

  React.useEffect(() => {
    if (logout) {
      removeToken();
      removeAuthUser();
      setAuthOptions({
        type: "set-auth-data",
        payload: { authToken: null, authUser: null, isLoading: false },
      });
      setLogout(false);
    }
  }, [logout]);

  const setAuthToken = React.useCallback(async (token) => {
    setAuthOptions({ type: "start-loading" });
    if (!token) {
      setLogout(true);
      return;
    }

    storeToken(token);
    setAuthOptions({
      type: "set-auth-data",
      payload: { isLoading: false },
    });

    // todo: implement get information user api
    // try {

    //     const authUser = await config?.authSetting?.getAuthUserService();
    //     if (authUser) {
    //         setAuthOptions({
    //             type: "set-auth-data",
    //             payload: {authToken: token, authUser: authUser, isLoading: false}
    //         });
    //         return;
    //     }
    //     setLogout(true);

    // } catch (error) {
    //     setLogout(true);
    //     console.error(error);
    // }
  }, []);

  //todo: maybe in next version
  const setRedirectPath = React.useCallback((redirectPath) => {
    setAuthOptions({ type: "set-redirect-path", payload: { redirectPath } });
  }, []);

  const setAuthData = React.useCallback((data) => {
    setAuthOptions({ type: "set-auth-data", payload: data });
    data.authUser && saveAuthUser(data.authUser);
  }, []);

  const contextValue = React.useMemo(() => {
    return {
      ...authOptions,
      setAuthData,
      setRedirectPath,
      setAuthToken,
      setAuthOptions,
    };
  }, [authOptions, setAuthData, setAuthToken, setRedirectPath]);

  React.useEffect(() => {
    const authen = async () => {
      if (!authOptions.authToken) {
        const isAuthenticated = await isAuthenticatedRouteOnly;
        if (isAuthenticated) {
          navigate(authOptions?.fallbackPath);
        }
      } else if (!authOptions.authUser) {
        setAuthToken(authOptions.authToken);
      } else if (isNotAuthenticatedRouteOnly) {
        if (firstTimePageLoad)
          // navigate(config.authSetting.redirectNotAuthenticatedPath ?? "/");
          firstTimePageLoad = false;
      }
    };

    authen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authOptions.authToken,
    authOptions.authUser,
    authOptions?.fallbackPath,
    isAuthenticatedRouteOnly,
    isNotAuthenticatedRouteOnly,
  ]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default JumboAuthProvider;
