import React, { useState } from "react";
import { Alert, Card, CardContent, Box, Typography } from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, Link } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import authServices from "../../../services/auth-services";
import PATH from "app/routes/path";
import Loading from "app/components/Loading";
import Logo from "app/shared/Logo";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useSnackbar } from "notistack";
import { deleteCookie } from "app/utils/appHelpers";
import {INSIGHT_HOST} from "app/utils/constants/paths";
const validationSchema = yup.object({
  old_password: yup
    .string("Enter your old password")
    .required("Password is required"),
  new_password: yup
    .string("Enter your new password")
    .required("Password is required"),
  confirm_password: yup
    .string()
    .required("Password Confirmation is required")
    .oneOf([yup.ref("new_password"), null], "New passwords must match"),
});

const ChangePassword = () => {
  const navigate = useNavigate();
  const { setAuthToken } = useJumboAuth();

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const mutation = { isError: false };
  const { enqueueSnackbar } = useSnackbar();

  const onReset = async (old_password, new_password, confirm_password) => {
    setLoading(true);

    const { error } = await authServices.changePassword({
      old_password,
      new_password,
      confirm_password,
    });

    setLoading(false);
    if (error) {
      setErrorMessage(
        "Something wrong occurred while processing your request. Try later!"
      );
      return;
    }
    enqueueSnackbar(
      "Your password has changed. Redirecting you to login page",
      {
        variant: "success",
      }
    );
    setAuthToken(null);
    navigate(PATH.login);
    deleteCookie("token");
  };

  return (
    <Box>
      <Box
        sx={{
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          "& .logo_wrapper": {
            mt: 5,
          },
          "& .logo_wrapper img": {
            maxWidth: "80px",
          },
        }}
      >
        <Logo />

        <Div
          className="card_wrapper"
          sx={{
            flex: 1,
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: (theme) => theme.spacing(0),
            "& a": {
              color: "#A9F000",
            },
          }}
        >
          <Typography component="h3" fontSize={"34px"} p={3}>
            Change password
          </Typography>
          <Card
            sx={{ maxWidth: "100%", width: 360, mb: 4 }}
            className="card_form"
          >
            <CardContent sx={{ pt: 5 }}>
              {errorMessage && (
                <Alert sx={{ mb: 3 }} severity="error">
                  {errorMessage}
                </Alert>
              )}
              <Formik
                validateOnChange={true}
                initialValues={{
                  old_password: "",
                  new_password: "",
                  confirm_password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                  setErrorMessage("");
                  setSubmitting(true);
                  onReset(
                    data.old_password,
                    data.new_password,
                    data.confirm_password
                  );
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    {mutation.isError && <p>{mutation.error.message}</p>}
                    <Div sx={{ mb: 3, mt: 1 }}>
                      <JumboTextField
                        fullWidth
                        name="old_password"
                        label="Old Password"
                        type="password"
                      />
                    </Div>
                    <Div sx={{ mb: 2, mt: 1 }}>
                      <JumboTextField
                        fullWidth
                        name="new_password"
                        label="New Password"
                        type="password"
                      />
                    </Div>
                    <Div sx={{ mb: 2, mt: 1 }}>
                      <JumboTextField
                        fullWidth
                        name="confirm_password"
                        label="Password Confirmation"
                        type="password"
                      />
                    </Div>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3, mt: 1 }}
                      loading={isSubmitting || mutation.isLoading}
                    >
                      Update
                    </LoadingButton>
                  </Form>
                )}
              </Formik>
              <Typography textAlign={"center"} variant={"body1"} mb={1}>
                <Link
                  underline="none"
                  to={PATH.home}
                  onClick={() => (window.location.href = INSIGHT_HOST)}
                >
                  Return home
                </Link>
              </Typography>
            </CardContent>
          </Card>
        </Div>
      </Box>
      <Loading isLoading={isLoading} />
    </Box>
  );
};

export default ChangePassword;
