import React from "react";
import "./loading.css";
import { Box } from "@mui/material";

const Loader = () => (
  <div className="loader">
    <svg className="circular-loader" viewBox="25 25 50 50">
      <circle className="loader-path" cx="50" cy="50" r="20" />
    </svg>
  </div>
);

const Loading = ({ isLoading }) => {
  return (
    isLoading && (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 100,
        }}
      >
        <Loader />
      </Box>
    )
  );
};

export default Loading;
