import React from "react";
import JumboNavIdentifier from "@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier";
import List from "@mui/material/List";
import PropTypes from "prop-types";

const JumboVerticalNavbar = ({ items }) => {
  return (
    <List
      disablePadding
      sx={{
        mr: 0,
        pb: 2,
      }}
    >
      {items.map((item, index) => (
        <JumboNavIdentifier translate item={item} key={index} />
      ))}
    </List>
  );
};

JumboVerticalNavbar.defaultProps = {
  items: PropTypes.array,
  translate: false,
};

export default JumboVerticalNavbar;
