import { HOST } from "app/utils/constants/paths";
import jwtAxios from "axios";

const jwtAuthAxios = jwtAxios.create({
  baseURL: HOST,
  headers: {
    "Content-Type": "application/json",
  },
});

const getFirstMessage = (errors) => {
  let message = "";
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      message += errors[key] && errors[key][0];
    }
  }
  return message;
};

jwtAuthAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    return Promise.reject(
      err.response.data && err.response.data.errors
        ? getFirstMessage(err.response.data.errors)
        : "Error with authen"
    );
  }
);

export const setAuthToken = (token) => {
  if (token) {
    jwtAuthAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
    localStorage.setItem("token", token);
  } else {
    delete jwtAuthAxios.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
  }
};

export const getAuthToken = () => {
  return localStorage.getItem("token");
};

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default jwtAuthAxios;
