import React, {useEffect} from "react";
import BasicLayout from "app/layouts/content-layouts/BasicLayout";
import WithLoading from "app/components/WithLoading";
import { INSIGHT_HOST } from "app/utils/constants/paths";
import authServices from "../../services/auth-services";

const Home = () => {
  useEffect(async () => {
    const { response, error } = await authServices.me();
    if (!error) {
      window.location.href = INSIGHT_HOST
    }
  }, []);

  return (
    <BasicLayout>
      <WithLoading
        isLoading={true}
      >
      </WithLoading>
    </BasicLayout>
  );
};

export default Home;
