import React from "react";

import { CardContent, Typography, Box, Card } from "@mui/material";
import NoImg from "app/assets/images/no_img.jpg";
import moment from "moment";
import "app/pages/reports/post.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import { stripTag } from "@jumbo/utils";
import usePageMeta from "app/hooks/usePageMeta";

export function PostDetails({ post }) {
  const mainImg =
    post._embedded &&
    Array.isArray(post._embedded["wp:featuredmedia"]) &&
    post._embedded["wp:featuredmedia"][0]
      ? post._embedded["wp:featuredmedia"][0].source_url
      : NoImg;

  usePageMeta(
    post.title && post.title.rendered,
    post.excerpt && stripTag(post.excerpt.rendered),
    mainImg,
    "article"
  );
  React.useEffect(() => {
    if (window.twttr) {
      window.twttr.widgets.load();
    }
  }, []);

  return (
    <Card
      sx={{
        "& a": {
          color: "#A9F000",
        },
      }}
      className="post_details"
    >
      <CardContent
        sx={{
          pt: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "800px",
            margin: "0 auto",
            pb: 2,
          }}
        >
          <Box
            sx={{
              pb: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Typography
              variant={"h1"}
              mb={0.5}
              mt={2}
              fontSize={48}
              color="#fff"
              className="has-text-align-left wp-block-post-title"
            >
              {post.title && post.title.rendered}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",

            pt: 2,
            pb: 2,
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
          <Box
            component="figure"
            className="wp-block-post-featured-image"
            sx={{ "& img": { maxWidth: "100%", height: "auto" } }}
          >
            <img src={mainImg} alt={`${post.title}`} />
          </Box>

          <Box
            sx={{
              mt: 4,
              fontSize: "18px",
              "& img": {
                maxWidth: "100%",
                height: "auto",
              },
            }}
            className="wp-block-content"
          >
            <Box
              className="entry-content wp-block-post-content is-layout-constrained wp-block-post-content-is-layout-constrained"
              color="inherit"
              dangerouslySetInnerHTML={{
                __html: post.content && post.content.rendered,
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                gap: "24px",
                alignItems: "center",
                borderTop: "1px solid #fff",
              }}
              pb={4}
              pt={4}
              mt={3}
            >
              <Box className="post_info">
                <PersonIcon />
                <Typography component="span">
                  {post._embedded && post._embedded.author[0].name}
                </Typography>
              </Box>
              <Box className="post_info">
                <CalendarMonthIcon />
                <Typography component="span">
                  {moment(post.date_gmt).format("DD/MM/YYYY")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
