import Div from '@jumbo/shared/Div';
import React from 'react';
import './title-page.css'

const TitlePage = (props) => {
    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        p: '8px 48px', 
        borderBottom: 1,
        borderColor: "divider", 
        minHeight: '64px'
    };

    return (
        <Div className='title-page' sx={style}>
            { props.children }
        </Div>
    );
};

export default TitlePage;