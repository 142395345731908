import requestService from "app/services";
import wpRequestService from "app/services/wp";
import { useEffect, useReducer } from "react";

const reducer = (state, action) => {
  const { isLoading, data, error } = action;
  switch (action.type) {
    case "setData":
      return { ...state, isLoading, data, error };
    case "setLoading":
      return { ...state, isLoading };
    default:
      throw new Error();
  }
};

const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

// useFetch is function call api
const useFetch = ({ path, options = {}, dependent = [] }, isMainApi = true) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dependentCustom = dependent.length ? [...dependent] : [dependent];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      dispatch({ type: "setLoading", isLoading: true });
      const { data } = isMainApi
        ? await requestService({ path, ...options })
        : await wpRequestService({ path, ...options });
      dispatch({
        type: "setData",
        isLoading: false,
        data: data,
        error: null,
      });
    } catch (error) {
      dispatch({ type: "setData", isLoading: false, data: null, error: error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependentCustom);

  return {
    ...state,
  };
};

export default useFetch;
