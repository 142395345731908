import { Box } from "@mui/material";

const Paragraph = ({ children }) => {
  return (
    <Box
      className="paragraph"
      sx={{
        margin: "0 30px 20px",
        paddingLeft: "30px",
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          left: 0,
          top: "8px",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
          background: "rgb(169,240,0)",
          boxShadow: "rgba(169,240,0, 0.2) 0px 0px 0px 5px",
        },
      }}
    >
      {children}
    </Box>
  );
};
export default Paragraph;
