import React from "react";
import useJumboApp from "@jumbo/hooks/useJumboApp";
import { config } from "../../../app/config/main";
import { useNavigate } from "react-router-dom";
import PATH from "app/routes/path";

const Page = ({ component, layout, isRedirect = false, ...restProps }) => {
  const { activeLayout, setActiveLayout } = useJumboApp();
  const isLoggedIn = !!localStorage.getItem("token");
  const navigate = useNavigate();

  React.useEffect(() => {
    if (layout !== activeLayout) {
      setActiveLayout(layout);
    }
  }, [activeLayout, layout, setActiveLayout]);

  const PageComponent = component;
  if (isRedirect) {
    if (isLoggedIn) {
      return <PageComponent {...restProps} />;
    } else {
      navigate(PATH.login);
    }
  }
  return <PageComponent {...restProps} />;
};

Page.defaultProps = {
  layout: config.defaultLayout,
};

export default Page;
