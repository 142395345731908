import React from "react";
import Avatar from "@mui/material/Avatar";
import {
  ListItemIcon,
  ThemeProvider,
  Typography,
  Box,
  Collapse,
} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { useNavigate } from "react-router-dom";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import PATH from "app/routes/path";
import { ReactComponent as CPCNFTs } from "app/assets/images/CPCNFTs.svg";
import { ReactComponent as Profile } from "app/assets/images/Profile.svg";
import { ReactComponent as Logout } from "app/assets/images/Log_out.svg";
import { ReactComponent as Wallet } from "app/assets/images/Wallet.svg";
import { ReactComponent as Subscription } from "app/assets/images/My_Subscription.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { deleteCookie } from "app/utils/appHelpers";

const AuthUserDropdown = () => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const { setAuthToken, authUser } = useJumboAuth();
  const [open, setOpen] = React.useState(false);

  const onLogout = () => {
    setAuthToken(null);
    navigate("/user/login");
    deleteCookie("token");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className={`avatar_header ${open ? "grey_bg" : ""}`}>
        <Box
          className="box_flex space_between profile_box"
          onClick={() => setOpen(!open)}
        >
          <Typography className="dark_text hide_mobile">
            Welcome,&nbsp;
            <Typography component="span" className="bright_text">
              {authUser && authUser.name}
            </Typography>
          </Typography>
          <Avatar
            src={authUser && authUser.profile_pic}
            sizes={"small"}
            sx={{ boxShadow: 25, cursor: "pointer" }}
          />
        </Box>
        <Collapse in={open} timeout={"auto"} unmountOnExit>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <nav className="profile_dropdown">
              <List disablePadding sx={{ pb: 1 }}>
                <ListItemButton onClick={() => navigate(PATH.account)}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <Profile />
                  </ListItemIcon>
                  <Typography className="dark_text">Profile</Typography>
                </ListItemButton>
                {/* <ListItemButton onClick={() => navigate(PATH.account)}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <CPCNFTs />
                  </ListItemIcon>
                  <Typography className="dark_text">CPC NFT</Typography>
                </ListItemButton>
                <ListItemButton onClick={() => navigate(PATH.subcription)}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <Subscription />
                  </ListItemIcon>
                  <Typography className="dark_text">My subcription</Typography>
                </ListItemButton>
                <ListItemButton onClick={() => navigate(PATH.connectWallet)}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <Wallet />
                  </ListItemIcon>
                  <Typography className="dark_text multiple_color">
                    Connect Wallet
                  </Typography>
                </ListItemButton> */}

                <ListItemButton onClick={onLogout}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <Logout />
                  </ListItemIcon>
                  <Typography className="dark_text">Logout</Typography>
                </ListItemButton>
              </List>
            </nav>
          </ClickAwayListener>
        </Collapse>
      </Box>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
