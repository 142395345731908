import { Typography, styled, Box } from "@mui/material";

export const CommonHeader = styled(Typography)(({ theme }) => ({
  margin: "24px 0",
  fontSize: "20px",
  lineHeight: "30px",
  textAlign: "center",
  color: "#ccc",
}));

export const FlexBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "20px",
  rowGap: "14px",
  placeItems: "flex-start",
}));
