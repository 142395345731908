import { Stack, Box, Tabs, Tab } from "@mui/material";
import React from "react";

import Div from "@jumbo/shared/Div";

import BasicLayout from "app/layouts/content-layouts/BasicLayout";
import TokenDistribution from "./components/TokenDistribution";
import Transactions from "./components/Transactions";
import Exchanges from "./components/Exchanges";
import SmartMoney from "./components/SmartMoney";
import FreshWalletToken from "./components/FreshWalletToken";
import TokenBalance from "./components/TokenBalance";
import useFetch from "app/hooks/useFetch";
import useQuery from "app/hooks/useQuery";
import "./balance.css";
import PATH from "app/routes/path";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, mt: 2 }}>
          <Div>{children}</Div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Balance = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(1);
  const { isLoading: isLoadingContractDetail, data: contractDetail } = useFetch(
    {
      path: "contracts",
      options: {
        params: {
          contract_address: query.get("contract_address"),
        },
      },
      dependent: query.get("contract_address"),
    }
  );
  if (!query.get("contract_address")) {
    navigate(PATH.tokenAnalytics);
  }
  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <BasicLayout header={<h1>{t("pages.tokenDistribution.header")}</h1>}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Stack
            direction="row"
            alignItems="flex-end"
            borderRight={1}
            borderColor="divider"
            paddingBottom={2}
            paddingTop={2}
          >
            <Div className="icon">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="sc-hUpaCq daGzFx"
              >
                <g fill="currentColor">
                  <path d="m21.057 7.964-6.446-.937-2.882-5.842a.816.816 0 0 0-1.457 0L7.388 7.027l-6.446.937a.811.811 0 0 0-.45 1.387l4.665 4.547-1.102 6.421a.81.81 0 0 0 1.178.856L11 18.143l5.766 3.032a.811.811 0 0 0 1.178-.856l-1.102-6.421 4.665-4.547a.811.811 0 0 0 .236-.465.81.81 0 0 0-.686-.922Zm-6.177 5.294.916 5.34L11 16.079 6.204 18.6l.916-5.34-3.88-3.783 5.363-.779L11 3.841l2.397 4.857 5.362.78-3.88 3.78Z"></path>
                </g>
              </svg>
            </Div>
            <Div sx={{ px: 1 }} className="sc-dkPtRN sc-jathZL gtJbld dWQFOx">
              <div className="sc-cnsdaU gHHGLp">Token</div>
              <Stack direction="row" alignItems="flex-end" spacing={1}>
                <Div
                  sx={{ fontSize: 16, color: "#fff" }}
                  className="contract-detail"
                >
                  {isLoadingContractDetail
                    ? "Loading..."
                    : `[${
                        (contractDetail && contractDetail[0]?.symbol) || ""
                      }] ${
                        contractDetail &&
                        (contractDetail[0]?.name || contractDetail[0]?.id)
                      }`}
                </Div>
                <span className="sc-dcNRmW bKMhsU">
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5.793 13.5H3a.5.5 0 0 1-.5-.5v-2.793a.5.5 0 0 1 .146-.353l7.5-7.5a.5.5 0 0 1 .708 0l2.792 2.792a.5.5 0 0 1 0 .708l-7.5 7.5a.5.5 0 0 1-.353.146ZM8.5 4 12 7.5M5.968 13.468 2.53 10.032" />
                    </g>
                  </svg>
                </span>
              </Stack>
            </Div>
            <Div
              data-target="tooltip"
              tabIndex={0}
              className="sc-jZktgq ibKTHT"
              sx={{ pr: 1 }}
            >
              <div className="sc-dkPtRN sc-jathZL sc-koaXiP gtJbld dWQFOx iGikfj">
                <span className="sc-dcNRmW bKMhsU">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    viewBox="0 0 256 256"
                    aria-hidden="true"
                  >
                    <rect width={256} height={256} fill="none" />
                    <polyline
                      points="220 176 220 36 80 36"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={24}
                    />
                    <rect
                      x={40}
                      y={76}
                      width={140}
                      height={140}
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={24}
                    />
                  </svg>
                </span>
              </div>
            </Div>
          </Stack>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Transactions" {...a11yProps(0)} />
            <Tab label="Token Distribution" {...a11yProps(1)} />
            <Tab label="Exchanges" {...a11yProps(2)} />
            <Tab label="Smart Money" {...a11yProps(2)} />
            <Tab label="Fresh Wallets For Token" {...a11yProps(2)} />
            <Tab label="Token Balance" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Transactions />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <TokenDistribution contractDetail={contractDetail} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Exchanges />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <SmartMoney />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <FreshWalletToken />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <TokenBalance />
        </CustomTabPanel>
      </Box>
    </BasicLayout>
  );
};

export default Balance;
