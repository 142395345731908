import React from "react";
import { useNavigate } from "react-router-dom";

import { Search, SearchIconWrapper, StyledInputBase } from "./style";
import { ReactComponent as SearchIcon } from "app/assets/images/Search.svg";
import { INSIGHT_HOST } from "app/utils/constants/paths";
// import {
//   Paper,
//   Popper,
//   ClickAwayListener,
//   Grow,
//   MenuList,
//   MenuItem,
//   Box,
// } from "@mui/material";
import PATH from "app/routes/path";

const SearchGlobal = ({ sx }) => {
  const [inputValue, setInputValue] = React.useState("");
  const navigate = useNavigate();

  const anchorRef = React.useRef(null);
  // const [open, setOpen] = React.useState(false);

  // const handleClose = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }

  //   setOpen(false);
  // };

  // const onChange = (e) => {
  //   setOpen(true);
  // };

  const onEnter = (e) => {
    if (e.key === "Enter" && inputValue) {
      // navigate(`${PATH.profileDetails}?address=${inputValue}`);
      window.location.href = `${INSIGHT_HOST}/?s=${encodeURIComponent(inputValue)}`;
    }
  };

  const onChange = (e) => {
    setInputValue(e.target && e.target.value);
  };

  return (
    <>
      <Search sx={sx} ref={anchorRef}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>

        <StyledInputBase
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          onKeyDown={onEnter}
          onChange={onChange}
        />
      </Search>
      {/* <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  sx={{ padding: 0 }}
                >
                  <MenuItem
                    onClick={() => navigate(PATH.profileDetails)}
                    sx={{ whiteSpace: "normal" }}
                  >
                    <Box
                      sx={{
                        maxWidth: "280px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "#ccc",
                      }}
                    >
                      ETH 0xeb2eb5c68156250c368914761bb8f1208d56acd0
                    </Box>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper> */}
    </>
  );
};

export default SearchGlobal;
